import Link from 'next/link';
import styled from 'styled-components';

import { colors } from '@hultafors/hellberg/helpers';

export const StyledLink = styled(Link)`
  color: ${colors.hellBlue};
  text-decoration: none;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    color: ${colors.gray0};
  }

  &:focus {
    color: inherit;
    box-shadow: none !important;
    outline: 0;
    border: 3px solid -webkit-focus-ring-color !important;
  }

  &:active {
    color: inherit;
  }

  &:visited {
    color: inherit;
  }
`;
