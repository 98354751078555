import Link from 'next/link';
import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

interface GenericPlugStyledProps {
  $image?: boolean;
}
export const GenericPlugStyled = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  height: 100%;

  .TextBoxTitle {
    margin-block-end: ${spacing.regular};
  }

  .TextBoxText {
    margin-block-end: ${spacing.regular};
  }

  .NoImagePlaceholder {
    block-size: 100%;
    inline-size: 100%;
    background-color: ${colors.gray6};
    aspect-ratio: 1;
  }
`;

export const TextBoxWrapper = styled.div<GenericPlugStyledProps>`
  padding-block-start: ${spacing.regular};

  ${({ $image }) =>
    !$image &&
    css`
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;

      h2 {
        font-size: ${fontSizes.h1};
      }

      p {
        font-size: ${fontSizes.body16};
        line-height: ${lineHeights.body16};
      }
    `}
`;

export const CTAWrapper = styled.div`
  display: flex;
  color: ${colors.hellBlue};
  align-items: center;

  p {
    color: ${colors.hellBlue};
    margin-inline-start: ${spacing.small};
  }

  &:hover p:hover,
  svg:hover {
    color: ${colors.hellBlueHover};
  }
`;
