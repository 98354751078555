import { useState } from 'react';

import { MuxPlayerRefAttributes } from '@mux/mux-player-react/.';

export const useMuxPlayer = () => {
  const [muted, setMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState<'playing' | 'paused' | 'stopped'>(
    'stopped',
  );

  const resetVideo = (
    videoEl: React.RefObject<MuxPlayerRefAttributes | null>,
  ) => {
    if (!videoEl?.current) {
      return;
    }

    videoEl?.current?.pause();
    setIsPlaying('stopped');
  };

  const pauseVideo = (
    videoEl?: React.RefObject<MuxPlayerRefAttributes | null>,
  ) => {
    if (!videoEl?.current) {
      return;
    }

    videoEl?.current?.pause();
    setIsPlaying('paused');
  };

  const playVideo = async (
    videoEl?: React.RefObject<MuxPlayerRefAttributes | null>,
  ) => {
    if (!videoEl?.current || !videoEl.current.media) {
      return;
    }

    videoEl.current.media.nativeEl.muted = muted;
    try {
      await videoEl?.current?.play();
      setIsPlaying('playing');
    } catch {
      setIsPlaying('stopped');
    }
  };

  const playVideoMuted = async (
    videoEl?: React.RefObject<MuxPlayerRefAttributes | null>,
  ) => {
    if (!videoEl?.current || !videoEl?.current?.media) return;

    videoEl.current.media.nativeEl.muted = true;

    try {
      await videoEl?.current?.play();
      setIsPlaying('playing');
    } catch {
      setIsPlaying('stopped');
    }
  };

  const toggleMuteBtn = (
    videoEl?: HTMLVideoElement | undefined,
    event?: React.MouseEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    if (!videoEl) {
      return;
    }

    videoEl.muted = !videoEl.muted;
    setMuted(!muted);
  };

  return {
    isPlaying,
    muted,
    pauseVideo,
    playVideo,
    playVideoMuted,
    resetVideo,
    setIsPlaying,
    toggleMuteBtn,
  };
};
