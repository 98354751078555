import { FC } from 'react';

import dynamic from 'next/dynamic';

import { ImageBasicFragment } from '@hultafors/shared/types';

import { HeroBlockFragment } from '@hultafors/hellberg/types';

import Logo from '../../svg/logo.svg';
import { ButtonLink } from '../ButtonLink/ButtonLink';
import { H1 } from '../H1/H1';
import { H2 } from '../H2/H2';

import styles from './hero-block.module.scss';
import {
  CenterWrapper,
  ContentWrapper,
  Description,
  DescriptionBox,
  HeroBlockContent,
  HeroBlockStyled,
  HeroButtonWrapper,
  HeroIcon,
  HeroTitle,
  VideoInner,
  VideoStyled,
} from './HeroBlock.styled';
import MuxPlayerContent from './mux-player-wrapper';

const ResponsiveImage = dynamic(
  () =>
    import('@hultafors/shared/components').then((mod) => mod.ResponsiveImage),
  { ssr: false },
);
interface HeroBlockProps extends HeroBlockFragment {
  icon?: ImageBasicFragment;
  priority?: boolean;
  isInSlider?: boolean;
}

export const HeroBlock: FC<HeroBlockProps> = ({
  title,
  description,
  border,
  ctaColor,
  firstCtaText: ctaTitle1,
  secondCtaText: ctaTitle2,
  firstCtaLink: ctaUrl1,
  secondCtaLink: ctaUrl2,
  mobileImage,
  tabletImage,
  desktopImage,
  icon,
  isInSlider,
  priority,
  video,
  useVideo,
  imageLoad,
}) => {
  let TitleComponent = H2;

  if (priority) {
    TitleComponent = H1;
  }

  return (
    <HeroBlockStyled
      $image={!!(desktopImage || tabletImage || mobileImage || useVideo)}
      className={styles['hero-block']}
    >
      {!useVideo && imageLoad && (
        <ResponsiveImage
          image={imageLoad}
          alt={title}
          aspectRatioPortrait={3 / 4}
          aspectRatioLandscape={12 / 5}
          priority={priority}
        />
      )}
      {useVideo && (
        <VideoStyled>
          <VideoInner>
            <MuxPlayerContent video={video} />
          </VideoInner>
        </VideoStyled>
      )}
      <HeroBlockContent>
        <ContentWrapper
          $borderColor={border && ctaColor?.hex}
          $isInSlider={isInSlider}
        >
          <CenterWrapper>
            {icon && (
              <HeroIcon color={ctaColor?.hex}>
                <Logo
                  aria-hidden={true}
                  focusable={false}
                  width={200}
                  height={200}
                />
              </HeroIcon>
            )}
            <HeroTitle as={TitleComponent}>{title}</HeroTitle>

            {description && (
              <DescriptionBox>
                <Description>{description}</Description>
              </DescriptionBox>
            )}
            {(ctaTitle1 || ctaTitle2) && (
              <HeroButtonWrapper
                $image={
                  !!(desktopImage || tabletImage || mobileImage || useVideo)
                }
              >
                <span>
                  {ctaTitle1 && ctaUrl1 && (
                    <ButtonLink
                      to={ctaUrl1 || ''}
                      className={`${ctaColor ? 'DatoColor' : 'Blue'}`}
                      color={ctaColor?.hex}
                    >
                      {ctaTitle1}
                    </ButtonLink>
                  )}
                  {ctaTitle2 && ctaUrl2 && (
                    <ButtonLink to={ctaUrl2} className="White">
                      {ctaTitle2}
                    </ButtonLink>
                  )}
                </span>
              </HeroButtonWrapper>
            )}
          </CenterWrapper>
        </ContentWrapper>
      </HeroBlockContent>
    </HeroBlockStyled>
  );
};
