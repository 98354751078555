import { useEffect, useId, useRef, useState } from 'react';

import {
  ProductFilter,
  ProductFilterValue,
  QueryProductFilter,
} from '@hultafors/shared/types';

import ChevronDown from '../../svg/chevron_down.svg';
import Trash from '../../svg/trash.svg';
import { Paragraph } from '../Paragraph/Paragraph';
import { Section } from '../Section/Section';
import { TextButton } from '../TextButton/TextButton';

import {
  Actions,
  Checkbox,
  ClearLabel,
  DropDownFilters,
  FilterLabel,
  FilterWrapper,
  Label,
  StyledBoxFilters,
  SubFilter,
  SubFilters,
  TextAndIcon,
} from './BoxFilters.styled';

interface BoxFiltersProps {
  filters: ProductFilter[];
  selectedFilters: ProductFilter[];
  filterChange({ AttrId, ValueId }: QueryProductFilter): void;
  clearFilter(): void;
  clearFilterLabel: string;
}

export const BoxFilters: React.FC<BoxFiltersProps> = ({
  selectedFilters = [],
  filters = [],
  clearFilterLabel = '',
  filterChange,
  clearFilter,
}) => {
  const uid = useId();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: { target: any }) => {
    if (ref.current && !ref.current?.contains(event.target)) {
      setActiveIndex(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const isSelectionUnderParent = (parentId: ProductFilter['id']) => {
    return !!selectedFilters.find((selectedParent) => {
      return selectedParent.id === parentId && !!selectedParent.values?.length;
    });
  };

  const filterHighlightedFilter = ({ isHighLighted }: ProductFilter) =>
    !isHighLighted;

  const filterMapper = (
    { label, values, id: parentId }: ProductFilter,
    index: number,
  ) => {
    const childFilterMapper = ({
      id: childId,
      active,
      description,
    }: ProductFilterValue) => {
      const key = `BoxFilters-${uid}-SubFilter-${childId}`;
      const onChange = () =>
        filterChange({
          AttrId: `${parentId}`,
          ValueId: `${childId}`,
        });
      return (
        <SubFilter key={key}>
          <Label $active={!!active}>
            <Checkbox onChange={onChange} checked={!!active} />
            <Paragraph styleType="body16">{description}</Paragraph>
          </Label>
        </SubFilter>
      );
    };
    const onClick: React.MouseEventHandler<HTMLButtonElement> = () => {
      const currentIndex = activeIndex === index ? null : index;
      setActiveIndex(currentIndex);
    };
    const key = `BoxFilters-${uid}-Filter-${parentId}`;
    return (
      <FilterWrapper key={key}>
        <TextAndIcon
          $active={activeIndex === index}
          $childSelected={isSelectionUnderParent(parentId)}
          onClick={onClick}
        >
          <FilterLabel>{label}</FilterLabel>{' '}
          <ChevronDown
            aria-hidden={true}
            focusable={false}
            width={16}
            height={16}
          />
        </TextAndIcon>
        <SubFilters $active={activeIndex === index}>
          {values.map(childFilterMapper)}
        </SubFilters>
      </FilterWrapper>
    );
  };

  return (
    <StyledBoxFilters>
      <Section noMargin maxWidth>
        <DropDownFilters ref={ref}>
          {filters?.filter(filterHighlightedFilter).map(filterMapper)}
          {!!selectedFilters.length && (
            <Actions>
              <TextButton onClick={clearFilter}>
                <ClearLabel>{clearFilterLabel}</ClearLabel>{' '}
                <Trash
                  aria-hidden={true}
                  focusable={false}
                  width={16}
                  height={16}
                />
              </TextButton>
            </Actions>
          )}
        </DropDownFilters>
      </Section>
    </StyledBoxFilters>
  );
};
