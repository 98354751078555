import { Fragment } from 'react';

import Image from 'next/image';
import Link from 'next/link';

import { useMarketPicker } from '@hultafors/shared/context/hooks';

import {
  GlobalFragment,
  NavItemChildFragment,
  NavItemFragment,
} from '@hultafors/hellberg/types';

import Globe from '../../svg/globe.svg';
import LogoFull from '../../svg/logo_full.svg';
import Search from '../../svg/search.svg';
import { DesktopDropdownMenu } from '../DesktopDropdownMenu/DesktopDropdownMenu';
import { IconButtonWrapper } from '../IconButtonWrapper/IconButtonWrapper';
import { OpenSettings } from '../Nav/Nav';
import { NavItem } from '../NavItem/NavItem';
import { NavLabel } from '../NavLabel/NavLabel';
import { Paragraph } from '../Paragraph/Paragraph';

import {
  ImageWrapper,
  NavigationDesktopStyled,
  TopActions,
  TopMenu,
  TopMenuButton,
  TopMenuLink,
} from './NavigationDesktop.styled';

interface NavigationDesktopProps {
  setIsOpen(openSettings: OpenSettings): void;
  defaultOpenSettings: OpenSettings;
  menu: NavItemFragment[];
  isOpen: OpenSettings;
  toggleFavoritesMenu(): void;
  toggleCartMenu(): void;
  global: GlobalFragment;
  toggleSearchMenu(): void;
  toggleMarketPicker(): void;
}

export const NavigationDesktop: React.FC<NavigationDesktopProps> = ({
  setIsOpen,
  defaultOpenSettings,
  menu,
  isOpen,
  toggleFavoritesMenu,
  toggleCartMenu,
  global,
  toggleSearchMenu,
  toggleMarketPicker,
}) => {
  const { activeMarket } = useMarketPicker();
  const datoLogo = global?.navigationLogo?.url;

  function renderLanguageIcon() {
    let iconUrl;

    if (!activeMarket || (!activeMarket?.flag && !activeMarket?.symbol?.url)) {
      return (
        <Globe aria-hidden={true} focusable={false} width={24} height={24} />
      );
    }
    if (activeMarket?.flag && !activeMarket?.symbol?.url) {
      iconUrl = `https://flagcdn.com/${activeMarket.flag}.svg`;
    }
    if (activeMarket?.symbol?.url) {
      iconUrl = activeMarket.symbol.url;
    }
    return (
      <img
        src={iconUrl ?? ''}
        width={24}
        height={24}
        alt={activeMarket.name ?? ''}
      />
    );
  }

  const toggleDesktopMenu = (type?: string) => {
    const open = !isOpen.desktopMenu;

    if (type === isOpen.desktop) {
      type = '';
    }

    setIsOpen({
      ...defaultOpenSettings,
      desktop: type || '',
      desktopMenu: open,
    });
  };
  function menuMapper(item: NavItemFragment, i: number) {
    const key = `MenuItemLink-${i}`;
    if (item?.children?.length) {
      const menuItems: NavItemChildFragment[] =
        (item?.children?.filter(Boolean) as NavItemChildFragment[]) ?? [];
      return (
        <Fragment key={key}>
          <TopMenuButton
            $active={isOpen.desktop === item.title}
            onClick={() => toggleDesktopMenu(item.title || '')}
          >
            <Paragraph styleType="body14Medium">{item.title}</Paragraph>
          </TopMenuButton>
          <DesktopDropdownMenu
            openType={isOpen.desktop}
            menuItems={menuItems}
            toggleDesktopMenu={toggleDesktopMenu}
            menuType={item.title || ''}
          />
        </Fragment>
      );
    }
    return (
      <TopMenuLink
        key={key}
        onClick={() => toggleDesktopMenu('main')}
        href={item.url ?? ''}
      >
        <Paragraph styleType="body14Medium">{item.title}</Paragraph>
      </TopMenuLink>
    );
  }
  return (
    menu && (
      <NavigationDesktopStyled>
        <div className="LogoTopMenuWrapper">
          <Link
            href="/"
            onClick={() => setIsOpen({ ...defaultOpenSettings })}
            aria-label="Hellberg Safety"
            className="Logo"
          >
            {/* //TODO remove logoSvg when they use datologo */}
            {datoLogo ? (
              <ImageWrapper>
                <Image
                  src={datoLogo}
                  alt="Hellberg Safety"
                  fill
                  style={{ objectFit: 'contain' }}
                  priority
                />
              </ImageWrapper>
            ) : (
              <LogoFull
                aria-hidden={true}
                focusable={false}
                width={121}
                height={24}
              />
            )}
          </Link>
          <TopMenu>{menu.map(menuMapper)}</TopMenu>
        </div>
        <TopActions>
          <IconButtonWrapper
            name="Search"
            className="Search"
            onClick={() => toggleSearchMenu()}
          >
            <Search
              aria-hidden={true}
              focusable={false}
              width={24}
              height={24}
            />
            <NavLabel>{global.search}</NavLabel>
          </IconButtonWrapper>
          <IconButtonWrapper
            name={global?.changeLanguage ?? ''}
            className="Globe"
            onClick={toggleMarketPicker}
          >
            {renderLanguageIcon()}
            <NavLabel>{global.changeLanguage}</NavLabel>
          </IconButtonWrapper>
          <NavItem
            urlToPartnerPortal={global?.urlToPartnerPortal}
            label={global.partnerPortalLabel}
          />
        </TopActions>
      </NavigationDesktopStyled>
    )
  );
};
