import { useId } from 'react';

import clsx from 'clsx';
import Image from 'next/image';

import { ParttrapValue } from '@hultafors/shared/types';

import { createDetailPageUrl } from '@hultafors/hellberg/helpers';
import {
  HellbergProduct,
  HellbergProductDetails,
  ParsedProduct,
} from '@hultafors/hellberg/types';

import PlusIcon from '../../svg/plus.svg';
import { Badge } from '../Badge/Badge';
import { Paragraph } from '../Paragraph/Paragraph';
import { ProductImage } from '../ProductImage/ProductImage';
import { RouterLink } from '../RouterLink/RouterLink';

import styles from './product.module.scss';

const ProductIcons: React.FC<{ icons?: ParttrapValue[] | null }> = ({
  icons,
}) => {
  const uid = useId();
  const slicedIcons = icons?.filter((icon) => !!icon?.image?.url)?.slice(0, 3);
  if (!slicedIcons?.length) {
    return null;
  }
  const iconMapper = (icon: ParttrapValue, index: number) => {
    return (
      <Image
        key={`ProductIcons-${uid}-${index}`}
        className={styles['product-icon']}
        src={icon?.image?.url ?? ''}
        alt={icon.text || 'icon image'}
        width={24}
        height={24}
      />
    );
  };
  return (
    <span className={styles['product-icon-wrapper']}>
      {slicedIcons?.map(iconMapper)}
    </span>
  );
};

interface ProductProps {
  product: HellbergProduct | HellbergProductDetails | ParsedProduct;
  badgeText?: string;
  inFavorites?: boolean;
  toggleMenu?(): void;
  rrpLabel: string;
  rrpIncludingVatLabel?: string;
  isSmall?: boolean;
  noHover?: boolean;
}

export const Product: React.FC<ProductProps> = ({
  product,
  badgeText,
  toggleMenu,
  rrpLabel,
  rrpIncludingVatLabel,
  isSmall,
  // inFavorites,
  // noHover,
}) => {
  const uid = useId();
  const to = createDetailPageUrl(product);

  const colorBadgeMapper = (item: ParttrapValue, index: number) => {
    if (!item.image || index >= 5) {
      return null;
    }

    return (
      <li key={`ColorBadge-${uid}-${item.text}`}>
        {index === 4 ? (
          <PlusIcon width="14px" height="14px" />
        ) : (
          <Image
            className={styles['colors-badge']}
            src={item.image?.url}
            alt={item.text || ''}
            width={16}
            height={16}
            sizes="16px"
          />
        )}
      </li>
    );
  };

  const handleOnClick = () => {
    toggleMenu?.();
  };

  return (
    <div
      className={clsx(
        styles['product'],
        isSmall && styles['product--is-small'],
        // !!noHover && styles['product--no-hover'], TODO remove?
      )}
    >
      <div className={clsx(styles['product-wrapper'], product.id)}>
        <div className={styles['product-image']}>
          <RouterLink
            className={styles['product-name']}
            to={to}
            onClick={handleOnClick}
          >
            <ProductImage url={product.image?.url} name={product.name} />
          </RouterLink>

          {product.isNew && (
            <Badge styleName="TopLeft" isNew={true}>
              {badgeText}
            </Badge>
          )}

          {product.colors && product.colors.length > 1 && (
            <ul className={styles['colors-badge-wrapper']}>
              {product.colors?.map(colorBadgeMapper)}
            </ul>
          )}

          <ProductIcons icons={product?.icons} />
        </div>
        <div className={styles['product-info']}>
          <RouterLink
            className={styles['product-name']}
            to={to}
            onClick={handleOnClick}
          >
            <Paragraph styleType="body14Medium">{product.name}</Paragraph>
          </RouterLink>
          {product.price && product.price > 0 ? (
            <div className={styles['product-price']}>
              <Paragraph styleType="body14">{`${product.currency} ${product.price}`}</Paragraph>
              <Paragraph styleType="body10" className="rrp">
                {`${rrpLabel} `}
              </Paragraph>
            </div>
          ) : product.priceWithVat && product.priceWithVat > 0 ? (
            <div className={styles['product-price']}>
              <Paragraph styleType="body14">{`${product.currency} ${product.priceWithVat}`}</Paragraph>
              <Paragraph styleType="body10" className="rrp">
                {`${rrpIncludingVatLabel} `}
              </Paragraph>
            </div>
          ) : (
            ''
          )}

          {product?.usp && product.usp.length > 0 && (
            <div className={styles.usp}>
              <ul>
                {product.usp.map((item: string, index: number) => (
                  <li key={`usp-l-${index}`}>{item}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
