import Link from 'next/link';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hellberg/helpers';

import { IconButtonWrapper } from '../IconButtonWrapper/IconButtonWrapper';

export const MenuToggler = styled(IconButtonWrapper)`
  flex: 1;
  display: flex;
  align-items: flex-start;
  width: ${spacing.regular};
`;

export const NavigationMobileStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  background-color: ${colors.white};
  align-items: center;
  padding-block: 0;
  padding-inline: ${spacing.regular};
  border-block-end: 1px solid ${colors.gray4};

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: none;
  }

  span {
    align-self: center;
  }

  @media screen and (min-width: ${breakpoints.bigMobile}) {
    margin-inline-start: 0;
  }
`;

export const Logo = styled(Link)`
  position: relative;
  width: 121px;
  height: 24px;
  color: ${colors.black};
`;

export const MenuActions = styled.div`
  align-self: center;
  display: flex;
  margin-inline-start: ${spacing.small};
  flex: 1;
  justify-content: flex-end;
  gap: ${spacing.tiny};

  > div {
    margin-inline-start: ${spacing.xsmall};
    display: flex;
  }

  @media screen and (min-width: ${breakpoints.bigMobile}) {
    gap: ${spacing.small};
  }
`;
