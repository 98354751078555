import { useEffect, useState } from 'react';

import { useLocalStorageValue } from '@react-hookz/web';

import { RENEW_INTERVAL, TIMED_DIALOG_KEY } from '@hultafors/hellberg/helpers';
import { useGlobal } from '@hultafors/hellberg/hooks';
import { TimedDialogFragment } from '@hultafors/hellberg/types';

import { SubscriptionDialog } from '../SubscriptionDialog/SubscriptionDialog';
interface TimeDialogProps {
  timedDialogData: TimedDialogFragment;
}
export const TimedDialog: React.FC<TimeDialogProps> = ({ timedDialogData }) => {
  const { siteLocale } = useGlobal();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const timedDialogRenewDate = useLocalStorageValue(
    `${TIMED_DIALOG_KEY}-${siteLocale?.parttrapMarket || 'com'}`,
  );
  const closeDialog = () => setIsDialogOpen(false);

  useEffect(() => {
    if (
      !timedDialogRenewDate.value ||
      Date.now() > Number(timedDialogRenewDate.value)
    ) {
      setTimeout(() => {
        const now = Date.now();

        setIsDialogOpen(true);
        timedDialogRenewDate.set(now + RENEW_INTERVAL);
      }, timedDialogData.delay * 1000);
    }
  }, []);

  if (!timedDialogData.formUrl) {
    return null;
  }

  if (!timedDialogData || !isDialogOpen) {
    return null;
  }

  return (
    <SubscriptionDialog
      formUrl={timedDialogData.formUrl}
      image={timedDialogData.image || undefined}
      onClose={closeDialog}
    />
  );
};
