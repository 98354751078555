import Link from 'next/link';

import { RouterLink } from '../RouterLink/RouterLink';

import { TextLinkStyled } from './TextLink.styled';
interface TextLinkProps {
  to: string;
  className?: string;
  iconUrl?: string;
  external?: boolean;
  children?: React.ReactNode;
}
export const TextLink: React.FC<TextLinkProps> = ({
  to,
  className,
  children,
  external,
}) => {
  return (
    <TextLinkStyled className={className}>
      <>
        {external ? (
          <Link href={to} rel="noreferrer noopener" target="_blank">
            {children}
          </Link>
        ) : (
          <RouterLink to={to}>{children}</RouterLink>
        )}
      </>
    </TextLinkStyled>
  );
};
