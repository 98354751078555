import dynamic from 'next/dynamic';

import { breakpoints } from '@hultafors/shared/constants';

import { getPlugLinkUrl } from '@hultafors/hellberg/helpers';
import { BigPlugBlockFragment } from '@hultafors/hellberg/types';

import { HTag } from '../HTag/HTag';
import { LinkBlock } from '../LinkBlock/LinkBlock';

import { BigPlugBlockStyled, ContentWrapper } from './BigPlugBlock.styled';

const DatoImage = dynamic(
  () => import('@hultafors/shared/components').then((mod) => mod.DatoImage),
  { ssr: false },
);

export const BigPlugBlock: React.FC<BigPlugBlockFragment> = ({
  title,
  image,
  imageLoad,
  ctaLabel,
  ctaLink,
  ctaUrl,
  background,
  fullWidth,
  flipSide,
}) => {
  const getLink = () => {
    if (ctaUrl) {
      return ctaUrl;
    }
    if (ctaLink && ctaLink?.__typename && ctaLink.slug) {
      return `${getPlugLinkUrl(ctaLink.__typename, ctaLink.slug)}`;
    }
    return '';
  };

  const sizes = [`(min-width: ${breakpoints.tablet}) 252px`, '100vw'].join(
    ', ',
  );

  return (
    <BigPlugBlockStyled
      $fullWidth={fullWidth}
      $flipSide={flipSide}
      $background={background?.hex}
    >
      <ContentWrapper>
        <HTag
          className="ContentTitle"
          type="h2"
          styleType={[
            { styleType: 'header2' },
            { breakpoint: 'desktop', styleType: 'header1' },
          ]}
        >
          {title}
        </HTag>
        {ctaLabel && <LinkBlock linkText={ctaLabel} linkUrl={getLink()} />}
      </ContentWrapper>
      <DatoImage image={imageLoad} aspectRatio={1} sizes={sizes} />
    </BigPlugBlockStyled>
  );
};
