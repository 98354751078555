import Image from 'next/image';

import { colors } from '@hultafors/hellberg/helpers';

import { ProductImageStyled } from './ProductImage.styled';

interface ProductImageProps {
  url?: string;
  name?: string;
}

export const ProductImage: React.FC<ProductImageProps> = ({ url, name }) => {
  const sizes = [
    '(min-width: 1536px) 447px',
    '(min-width: 1276px) 33vw',
    '(min-width: 866px) 50vw',
    '100vw',
  ].join(', ');
  return (
    <ProductImageStyled>
      <Image
        src={
          url
            ? `${url}?w=2880&format=jpg&bgcolor=${colors.gray6.substring(1)}`
            : '/assets/gfx/noImage.png'
        }
        alt={name ?? ''}
        sizes={sizes}
        style={{ objectFit: 'contain' }}
        fill
      />
    </ProductImageStyled>
  );
};
