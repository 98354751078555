import dynamic from 'next/dynamic';

import {
  OfficeFragment,
  OfficesBlockFragment,
} from '@hultafors/hellberg/types';

import { FindRetailersListStyled } from '../FindRetailersList/FindRetailersList.styled';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';
import { StoreItem } from '../StoreItem/StoreItem';

import {
  ContactStoresStyled,
  OfficeAccordionItem,
} from './ContactStores.styled';

const Accordion = dynamic(() =>
  import('../Accordion/Accordion').then((mod) => mod.Accordion),
);

export interface ContactStoresProps {
  salesOfficesLabel: string;
  exportPartnersLabel: string;
  viewOnMapLabel: string;
  localWebLabel: string;
  offices: OfficeFragment[];
}

export const ContactStores: React.FC<
  ContactStoresProps | OfficesBlockFragment
> = ({
  salesOfficesLabel,
  exportPartnersLabel,
  viewOnMapLabel,
  localWebLabel,
  offices = [],
}) => {
  if (!viewOnMapLabel || !localWebLabel || !offices?.length) {
    return null;
  }

  const generateGoogleMapLinks = (
    name: string,
    address: string,
    zipCode: string,
  ) => {
    const baseLink = 'https://www.google.com/maps/search/?api=1&query=';
    return `${baseLink}${name ? name : ''}+${address ? address : ''}+${
      zipCode ? zipCode : ''
    }`;
  };

  const salesOffices: OfficeFragment[] =
    offices.filter(
      (item: OfficeFragment) => item.typeOfOffice === 'Sales office',
    ) || [];
  const exportMarkets: OfficeFragment[] =
    offices.filter(
      (item: OfficeFragment) => item.typeOfOffice === 'Export markets',
    ) || [];

  const renderAccordion = (items: OfficeFragment[]) => {
    return (
      <FindRetailersListStyled className="AccordionWrapper">
        <Accordion allowZeroExpanded>
          {items.map((item, index) => (
            <OfficeAccordionItem
              key={`Store-${index}`}
              label={item.name}
              plusIcon
            >
              <StoreItem
                label={item.name}
                address={item.address}
                zipCode={item.zipCode}
                city={item.city}
                mapLink={generateGoogleMapLinks(
                  item.name ?? '',
                  item.address ?? '',
                  item.zipCode ?? '',
                )}
                view_on_map={viewOnMapLabel}
                emailAddress={item.email}
                phone={item.phoneNumber}
                website={item.websiteUrl}
                visit_web_site={localWebLabel}
              />
            </OfficeAccordionItem>
          ))}
        </Accordion>
      </FindRetailersListStyled>
    );
  };

  return (
    <ContactStoresStyled>
      <Grid columnGap={0}>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 8, start: 3 },
            { breakpoint: 'desktop', columns: 6, start: 4 },
          ]}
        >
          <HTag type="h3" styleType="header3">
            {salesOfficesLabel}
          </HTag>

          {renderAccordion(salesOffices)}

          <HTag type="h3" styleType="header3">
            {exportPartnersLabel}
          </HTag>

          {renderAccordion(exportMarkets)}
        </GridChild>
      </Grid>
    </ContactStoresStyled>
  );
};
