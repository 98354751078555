import Markdown from 'markdown-to-jsx';

import { TextBlockFragment } from '@hultafors/hellberg/types';

import { HTag } from '../HTag/HTag';

import { TextBlockStyled } from './TextBlock.styled';

interface TextBlockProps extends TextBlockFragment {
  className?: string;
}

export const TextBlock: React.FC<TextBlockProps> = ({
  title,
  body,
  bigHeader,
  className,
  scrollId,
}) => {
  const sanitizedScrollId = scrollId?.replace(/[^a-zA-Z0-9-_:.]/g, '');

  return (
    <TextBlockStyled
      id={sanitizedScrollId || undefined}
      className={className}
      $hasMarkDown={!!body}
    >
      {title && (
        <HTag
          type={bigHeader ? 'h2' : 'h3'}
          styleType={bigHeader ? 'header3' : 'header4'}
        >
          {title}
        </HTag>
      )}
      <Markdown options={{ forceBlock: true }} className="markdown-wrapper">
        {body ?? ''}
      </Markdown>
    </TextBlockStyled>
  );
};
