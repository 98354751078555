import styled, { css } from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
} from '@hultafors/hellberg/helpers';

export type ParagraphStyleType =
  | 'body16Medium'
  | 'body16'
  | 'body14Medium'
  | 'body14'
  | 'body12Medium'
  | 'body12'
  | 'body10';

interface StyledParagraphProps {
  $styleType?: ParagraphStyleType;
  $center?: boolean;
  $noMargin?: boolean;
}

const fontStyle = ({ $styleType }: StyledParagraphProps) => {
  switch ($styleType) {
    case 'body16Medium':
      return css`
        font-size: ${fontSizes.body16};
        line-height: ${lineHeights.body16};
        font-weight: ${fontWeights.fontMedium};
        font-family: ${fontFamilies.fontRegularBold};
      `;
    case 'body16':
      return css`
        font-size: ${fontSizes.body16};
        line-height: ${lineHeights.body16};
        font-weight: ${fontWeights.fontRegular};
        font-family: ${fontFamilies.fontRegular};
      `;
    case 'body14Medium':
      return css`
        font-size: ${fontSizes.body14};
        line-height: ${lineHeights.body14};
        font-weight: ${fontWeights.fontMedium};
        font-family: ${fontFamilies.fontRegularBold};
      `;
    case 'body14':
      return css`
        font-size: ${fontSizes.body14};
        line-height: ${lineHeights.body14};
        font-weight: ${fontWeights.fontRegular};
        font-family: ${fontFamilies.fontRegular};
      `;
    case 'body12Medium':
      return css`
        font-size: ${fontSizes.body12};
        line-height: ${lineHeights.body12};
        font-weight: ${fontWeights.fontMedium};
        font-family: ${fontFamilies.fontRegularBold};
      `;
    case 'body12':
      return css`
        font-size: ${fontSizes.body12};
        line-height: ${lineHeights.body12};
        font-weight: ${fontWeights.fontRegular};
        font-family: ${fontFamilies.fontRegular};
      `;
    case 'body10':
      return css`
        font-size: ${fontSizes.body10};
        line-height: ${lineHeights.body10};
        font-weight: ${fontWeights.fontRegular};
        font-family: ${fontFamilies.fontRegular};
      `;
    default:
      return css`
        font-size: ${fontSizes.body14};
        line-height: ${lineHeights.body14};
        font-weight: ${fontWeights.fontRegular};
        font-family: ${fontFamilies.fontRegular};
      `;
  }
};

export const StyledParagraph = styled.p<StyledParagraphProps>`
  color: ${colors.black};
  ${fontStyle}
  ${({ $center }) =>
    $center &&
    css`
      text-align: center;
    `}
  ${({ $noMargin }) =>
    $noMargin &&
    css`
      margin: 0;
    `}
`;
