import { MouseEventHandler } from 'react';

import Image from 'next/image';

import { createDetailPageUrl } from '@hultafors/hellberg/helpers';
import { ParsedProduct } from '@hultafors/hellberg/types';

import CloseCircle from '../../svg/close_circle.svg';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { Paragraph } from '../Paragraph/Paragraph';

import {
  ArtNo,
  CloseButton,
  PriceLabel,
  ProductDetails,
  ProductImage,
  ProductInfo,
  ProductListItem,
  ProductListViewStyled,
  ProductName,
  ProductPrice,
} from './ProductListView.styled';

interface ProductListViewProps {
  product: ParsedProduct;
  removeItem?(e: any, item: ParsedProduct): void;
  rrpLabel?: string;
  rrpIncludingVatLabel?: string;
  artNoLabel?: string;
  toggleMenu?: MouseEventHandler;
}

export const ProductListView: React.FC<ProductListViewProps> = ({
  toggleMenu,
  product,
  rrpLabel,
  rrpIncludingVatLabel,
  artNoLabel,
  removeItem,
  ...props
}) => {
  const formatColor = (color: string) => {
    return color.substring(1, color.length);
  };
  // const imageUrl = product.image?.url || product.images[0].url;
  const imageUrl = product.image?.url;

  const href = createDetailPageUrl(product);
  return (
    <ProductListViewStyled>
      <ProductListItem href={href} onClick={toggleMenu}>
        <Grid columns={8} columnGap={[{ columnGap: 24 }]}>
          <GridChild columnSpan={[{ columns: 3 }]}>
            <ProductImage>
              <Image
                src={imageUrl || '/assets/gfx/noimage.png'}
                alt={product.name}
                width={120}
                height={120}
              />
            </ProductImage>
          </GridChild>
          <GridChild columnSpan={[{ columns: 5 }]}>
            <ProductInfo>
              <ProductName>{product.name}</ProductName>
              <ProductDetails>
                <ArtNo>
                  <label>{artNoLabel}</label> {product.modelSku ?? product.sku}
                </ArtNo>
                {(!!product.price || !!product.priceWithVat) && (
                  <ProductPrice>
                    <Paragraph styleType="body16">
                      {` ${product.currency} ${
                        product.price ?? product.priceWithVat
                      }`}
                    </Paragraph>
                    <PriceLabel>
                      {product.price ? rrpLabel : rrpIncludingVatLabel}
                    </PriceLabel>
                  </ProductPrice>
                )}
              </ProductDetails>
            </ProductInfo>
          </GridChild>
        </Grid>
      </ProductListItem>
      {removeItem && (
        <CloseButton onClick={(e) => removeItem(e, product)}>
          <CloseCircle
            aria-hidden={true}
            focusable={false}
            width={16}
            height={16}
          />
        </CloseButton>
      )}
    </ProductListViewStyled>
  );
};
