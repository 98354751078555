import { useEffect, useRef, useState } from 'react';

import { MuxPlayerRefAttributes } from '@mux/mux-player-react/.';
import clsx from 'clsx';
import { useWindowSize } from 'react-use';

import { breakpointsRaw } from '@hultafors/shared/constants';

import { VideoFragment } from '@hultafors/hellberg/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';

import MuxPlayerContent from './mux-player';
import styles from './video-block.module.scss';

export const VideoBlock: React.FC<VideoFragment> = ({
  video,
  scrollId,
  narrow,
}) => {
  const [paused, setPaused] = useState<boolean>(true);
  const videoPlayer = useRef<MuxPlayerRefAttributes | null>(null);

  const [playbackId, setPlaybackId] = useState<string | undefined>(
    video?.video?.muxPlaybackId ?? undefined,
  );

  const [placeholder, setPlaceholder] = useState<string | undefined>(
    video?.blurUpThumb ?? undefined,
  );

  const { width } = useWindowSize();

  const sanitizedScrollId = scrollId?.replace(/[^a-zA-Z0-9-_:.]/g, '');

  useEffect(() => {
    if (width && width > breakpointsRaw.tablet) {
      setPlaybackId(video?.video?.muxPlaybackId ?? undefined);
      setPlaceholder(video?.blurUpThumb ?? undefined);
    }
  }, [width]);

  function playVideo() {
    if (!videoPlayer?.current) {
      return;
    }
    if (paused === true) {
      setPaused(false);
      videoPlayer.current.play();
    }

    if (paused === false) {
      setPaused(true);
      videoPlayer.current.pause();
    }
  }

  if (!playbackId) {
    return null;
  }

  return (
    <section
      className={clsx(styles['video-block'], narrow && styles['narrow'])}
      id={sanitizedScrollId}
    >
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            narrow
              ? { breakpoint: 'mobileMax', columns: 8, start: 3 }
              : { breakpoint: 'mobileMax', columns: 10, start: 2 },
          ]}
        >
          <div className={styles['wrapper']}>
            <MuxPlayerContent video={video} />
          </div>
        </GridChild>
      </Grid>
    </section>
  );
};
