import styled, { CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hellberg/helpers';

export const DualImageBlockStyled = styled.div`
  max-width: calc(100vw - 40px);
  width: 1000px;
  margin-inline: auto;
  margin-block: ${spacing.regular};
  scroll-margin: var(--scroll-margin);
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;
  width: 100%;
  height: 100%;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    flex-direction: row;
  }
`;

interface InnerProps {
  $aspectRatio?: CSSProperties['aspectRatio'];
}

export const Inner = styled.div<InnerProps>`
  position: relative;
  flex-direction: column;
  height: 100%;
  width: 100%;
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || '9 / 5'};
`;
