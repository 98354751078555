import { useId } from 'react';

import dynamic from 'next/dynamic';

import { breakpoints } from '@hultafors/shared/constants';

import { PagePlugFragment } from '@hultafors/hellberg/types';

import { PlugsGrid } from '../PlugsGrid/PlugsGrid';

import {
  PagePlugsStyled,
  PagePlugStyled,
  StyledLink,
} from './PagePlugs.styled';

const DatoImage = dynamic(
  () => import('@hultafors/shared/components').then((mod) => mod.DatoImage),
  { ssr: false },
);

interface PagePlugsProps {
  plugs: PagePlugFragment[];
}

export const PagePlugs: React.FC<PagePlugsProps> = ({ plugs }) => {
  const uid = useId();
  function plugMapper(
    { image, url, title, imageLoad }: PagePlugFragment,
    i: number,
  ) {
    if (!image?.responsiveImage?.src || !url || !title) {
      return null;
    }
    const sizes = [
      `(min-width: ${breakpoints.desktop}) 25vw`,
      `(min-width: ${breakpoints.tablet}) 28vw`,
      '100vw',
    ].join(', ');
    return (
      <PagePlugStyled key={`PagePlugs-${uid}-${i}`}>
        <DatoImage image={imageLoad} sizes={sizes} aspectRatio={1} />
        <StyledLink href={url}>{title}</StyledLink>
      </PagePlugStyled>
    );
  }
  return (
    <PagePlugsStyled>
      <PlugsGrid total={plugs.length} gap={32}>
        {plugs.map(plugMapper)}
      </PlugsGrid>
    </PagePlugsStyled>
  );
};
