import { ImageFragment } from '@hultafors/hellberg/types';

import Close from '../../svg/close.svg';
import { SquareImage } from '../SquareImage/SquareImage';

import {
  BackDrop,
  DialogContent,
  ImageWrapper,
  StyledDialog,
  StyledIframeContainer,
} from './SubscriptionDialog.styled';
interface SubscriptionDialogProps {
  formUrl?: string | null;
  image?: ImageFragment | null;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
}
export const SubscriptionDialog: React.FC<SubscriptionDialogProps> = ({
  formUrl,
  image,
  onClose,
}) => {
  return (
    <>
      <BackDrop />
      <StyledDialog
        isFullscreen={false}
        onClose={onClose}
        closeButtonLabel={
          <Close aria-hidden={true} focusable={false} width={16} height={16} />
        }
      >
        <DialogContent>
          {image && (
            <ImageWrapper>
              <SquareImage image={image} />
            </ImageWrapper>
          )}
          {formUrl && (
            <StyledIframeContainer title="dialog-form" src={formUrl} />
          )}
        </DialogContent>
      </StyledDialog>
    </>
  );
};
