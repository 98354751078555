import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/hellberg/helpers';

export const SingleProductImageStyled = styled.div`
  position: relative;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    margin: 5vw;
    margin-block-start: 100px;
  }
`;

export const ImageWrapper = styled.div`
  aspect-ratio: 4 / 3;
  background: ${colors.gray5};
  position: relative;
  display: flex;
  justify-content: center;
  padding: 56px;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    aspect-ratio: 1 / 1;
  }

  img {
    background: ${colors.gray5};
  }
`;

export const ZoomContainer = styled.div`
  display: none;
  justify-content: flex-end;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    display: flex;
    position: absolute;
    inset-block-start: 0;
    inset-inline: 0;
    z-index: 2;
  }
`;
