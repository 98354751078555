import { GreyInfoBoxFragment } from '@hultafors/hellberg/types';

import { HTag } from '../HTag/HTag';
import { Markdown } from '../Markdown/Markdown';
import { Paragraph } from '../Paragraph/Paragraph';

import { Content, StyledGreyInfoBox } from './GreyInfoBoxBlock.styled';

export const GreyInfoBoxBlock: React.FC<GreyInfoBoxFragment> = ({
  title,
  body,
  scrollId,
}) => {
  const sanitizedScrollId =
    scrollId?.replace(/[^a-zA-Z0-9-_:.]/g, '') || undefined;

  const options = {
    overrides: {
      p: {
        component: Paragraph,
        props: {
          styleType: 'body16',
        },
      },
    },
  };

  return (
    <StyledGreyInfoBox id={sanitizedScrollId}>
      <Content>
        {title && (
          <HTag type="h2" styleType="header4">
            {title}
          </HTag>
        )}
        <Markdown options={options}>{body ?? ''}</Markdown>
      </Content>
    </StyledGreyInfoBox>
  );
};
