import { useId } from 'react';

import dynamic from 'next/dynamic';

import {
  DynamicContentField,
  isBlueInfoBox,
  isBulletList,
  isDocumentBlockNew,
  isDotDigitalForm,
  isGreyInfoBox,
  isImageBlock,
  isImageSlider,
  isLinkBoxBlock,
  isQuote,
  isTextBlock,
  isTwoImageBlock,
  isVideo,
} from '@hultafors/hellberg/types';

import { StyledDynamicContent } from './DynamicContent.styled';

const BlueInfoBox = dynamic(() =>
  import('../BlueInfoBox/BlueInfoBox').then((mod) => mod.BlueInfoBox),
);
const BulletList = dynamic(() =>
  import('../BulletList/BulletList').then((mod) => mod.BulletList),
);

const DocumentBlock = dynamic(() =>
  import('../document-block/document-block').then((mod) => mod.DocumentBlock),
);

const DualImageBlock = dynamic(() =>
  import('../DualImageBlock/DualImageBlock').then((mod) => mod.DualImageBlock),
);
const GreyInfoBox = dynamic(() =>
  import('../GreyInfoBox/GreyInfoBox').then((mod) => mod.GreyInfoBox),
);
const IframeContainer = dynamic(() =>
  import('../iframe-container/iframe-container').then(
    (mod) => mod.IframeContainer,
  ),
);
const ImageBlock = dynamic(() =>
  import('../ImageBlock/ImageBlock').then((mod) => mod.ImageBlock),
);
const ImageSlider = dynamic(() =>
  import('../image-slider/image-slider').then((mod) => mod.ImageSlider),
);
const LinkBoxBlock = dynamic(() =>
  import('../LinkBoxBlock/LinkBoxBlock').then((mod) => mod.LinkBoxBlock),
);
const Quote = dynamic(() => import('../Quote/Quote').then((mod) => mod.Quote));
const TextBlock = dynamic(() =>
  import('../TextBlock/TextBlock').then((mod) => mod.TextBlock),
);
const VideoBlock = dynamic(() =>
  import('../video-block/video-block').then((mod) => mod.VideoBlock),
);

interface DynamicContentProps {
  content: DynamicContentField[];
}
export const DynamicContent: React.FC<DynamicContentProps> = ({ content }) => {
  const uid = useId();

  if (!content.length) {
    return null;
  }

  function contentMapper(item: DynamicContentField, index: number) {
    const key = `DynamicContent-${uid}-${index}`;
    if (isBlueInfoBox(item)) {
      return (
        <BlueInfoBox key={key} body={item.body} scrollId={item.scrollId} />
      );
    }
    if (isBulletList(item)) {
      return (
        <BulletList
          key={key}
          title={item.title ?? ''}
          body={item.list ?? ''}
          isBig={item.isBigBulletList}
          scrollId={item?.scrollId}
          // link={item.link}
        />
      );
    }
    if (isDocumentBlockNew(item)) {
      return (
        <DocumentBlock
          key={key}
          document={item.document}
          title={item.title}
          scrollId={item.scrollId}
        />
      );
    }
    if (isDotDigitalForm(item)) {
      return (
        <IframeContainer
          key={key}
          scrollId={item.scrollId}
          title="Form"
          src={item.formUrl ?? ''}
          height={`${item.formHeight}px`}
        />
      );
    }
    if (isGreyInfoBox(item)) {
      return (
        <GreyInfoBox
          key={key}
          title={item.title ?? ''}
          body={item.body ?? ''}
          scrollId={item.scrollId}
        />
      );
    }
    if (isImageBlock(item)) {
      return (
        <ImageBlock
          key={key}
          image={item.image}
          imageLoad={item.imageLoad}
          label={item.label}
          small={item.small}
          scrollId={item.scrollId}
        />
      );
    }
    if (isImageSlider(item)) {
      return (
        <ImageSlider key={key} images={item.images} scrollId={item.scrollId} />
      );
    }
    if (isLinkBoxBlock(item)) {
      return (
        <LinkBoxBlock
          key={key}
          title={item.title}
          links={item.links}
          scrollId={item.scrollId}
        />
      );
    }
    if (isQuote(item)) {
      return (
        <Quote
          key={key}
          body={item.body ?? ''}
          author={item.author ?? ''}
          authorProfession={item.authorProfession ?? ''}
          scrollId={item.scrollId}
        />
      );
    }
    if (isTextBlock(item)) {
      return (
        <TextBlock
          key={key}
          body={item.body}
          bigHeader={item.bigHeader}
          title={item.title}
          scrollId={item.scrollId}
          className="TextBlock"
        />
      );
    }
    if (isTwoImageBlock(item)) {
      return (
        <DualImageBlock
          key={key}
          images={item.images}
          dynamicImages={item.dynamicImages}
          unlockedAspectRatio={item.unlockedAspectRatio}
          scrollId={item.scrollId}
        />
      );
    }
    if (isVideo(item)) {
      return (
        <VideoBlock key={key} video={item.video} scrollId={item.scrollId} />
      );
    }

    return null;
  }

  return (
    <StyledDynamicContent>{content.map(contentMapper)}</StyledDynamicContent>
  );
};
