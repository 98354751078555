import styles from './iframe-container.module.scss';
export type IframeContainerProps =
  React.IframeHTMLAttributes<HTMLIFrameElement> & {
    scrollId?: string | null | undefined;
  };

export const IframeContainer: React.FC<IframeContainerProps> = ({
  title,
  src,
  height = '100%',
  width = '100%',
  scrollId,
  ...rest
}) => {
  const sanitizedScrollId =
    scrollId?.replace(/[^a-zA-Z0-9-_:.]/g, '') || undefined;

  const style = {
    '--height': height,
    '--width': width,
  } as React.CSSProperties;

  return (
    <div
      className={styles['iframe-container']}
      style={style}
      id={sanitizedScrollId}
    >
      <iframe title={title} src={src} width={width} height={height} {...rest} />
    </div>
  );
};
