import dynamic from 'next/dynamic';

import { breakpoints } from '@hultafors/shared/constants';

import { ImageLoadFragment } from '@hultafors/hellberg/types';

import ArrowRight from '../../svg/arrow_right.svg';
import { HTag } from '../HTag/HTag';
import { Paragraph } from '../Paragraph/Paragraph';

import {
  CTAWrapper,
  GenericPlugStyled,
  TextBoxWrapper,
} from './GenericPlug.styled';

const DatoImage = dynamic(
  () => import('@hultafors/shared/components').then((mod) => mod.DatoImage),
  { ssr: false },
);

interface GenericPlugProps {
  title: string;
  url: string;
  image?: ImageLoadFragment;
  description?: string;
  ctaLabel?: string;
}

export const GenericPlug: React.FC<GenericPlugProps> = ({
  title,
  url,
  image,
  description,
  ctaLabel,
}) => {
  const sizes = [
    '(min-width: 1852px) 33vw',
    '(min-width: 1232px) 40vw',
    `(min-width: ${breakpoints.desktop}) 85vw`,
    `(min-width: ${breakpoints.tablet}) 90vw`,
    '100vw',
  ].join(', ');

  return (
    <GenericPlugStyled href={url} passHref>
      {image?.url ? (
        <DatoImage image={image} sizes={sizes} aspectRatio={1} />
      ) : (
        <div className="NoImagePlaceholder" />
      )}
      <TextBoxWrapper $image={!!image}>
        <HTag type="h2" styleType="header4" className="TextBoxTitle">
          {title}
        </HTag>
        <Paragraph styleType="body16" className="TextBoxText">
          {description ? description : '\u00A0'}
        </Paragraph>
        <CTAWrapper>
          <ArrowRight
            aria-hidden={true}
            focusable={false}
            width={16}
            height={16}
          />
          {ctaLabel && (
            <Paragraph styleType="body16" className="TextBoxCTA">
              {ctaLabel}
            </Paragraph>
          )}
        </CTAWrapper>
      </TextBoxWrapper>
    </GenericPlugStyled>
  );
};
