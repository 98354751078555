import { useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';
import { KeyLoader } from 'swr';
import useSWRInfinite from 'swr/infinite';

import {
  ParsedProductsApiResponse,
  ProductListResponse,
} from '@hultafors/shared/types';

import { PAGE_SIZE_L } from '@hultafors/hellberg/helpers';
import { useGlobal } from '@hultafors/hellberg/hooks';
import {
  GenericCategoryFragment,
  HellbergProduct,
  SearchPageFragment,
} from '@hultafors/hellberg/types';

import { Grid } from '../Grid/Grid';
import { Loader } from '../Loader/Loader';
import { Pager } from '../Pager/Pager';
import { Products } from '../Products/Products';
import { SearchInput } from '../SearchInput/SearchInput';
import { SearchResultHero } from '../SearchResultHero/SearchResultHero';
import { Section } from '../Section/Section';

interface SearchPageContentProps {
  content: SearchPageFragment;
  genericCategory: GenericCategoryFragment;
}

export const SearchPageContent: React.FC<SearchPageContentProps> = ({
  genericCategory,
}) => {
  const { shared, siteLocale } = useGlobal();
  const [term, setTerm] = useState<string>('');

  const [searchValue, setSearchValue] = useState(term);
  const { locale, ...router } = useRouter();

  // Creates api path that also acts as cache key for swr
  const getKey: KeyLoader = (
    index: number,
    previousPageData: ParsedProductsApiResponse<HellbergProduct> | null,
  ) => {
    if (
      previousPageData &&
      previousPageData.paging.pageCount === previousPageData.paging.pageNumber
    ) {
      return null;
    }

    const path = '/api/searchlist';

    const searchParams = new URLSearchParams({
      includeFilter: 'false',
      market: siteLocale.parttrapMarket ?? '',
      pageNumber: `${index + 1}`,
      pageSize: `${PAGE_SIZE_L}`,
      searchValue,
      siteId: siteLocale.parttrapSiteId ?? '',
    });

    if (siteLocale.parttrapLanguage) {
      searchParams.set('lang', siteLocale.parttrapLanguage);
    }

    return [path, searchParams.toString()].join('?');
  };

  // Initialize swr for search results fetching
  const { data, isLoading, isValidating, size, setSize } =
    useSWRInfinite<ProductListResponse<HellbergProduct>>(getKey);

  const products = useMemo(() => {
    return data?.flatMap(({ items }) => items) || [];
  }, [data]);

  const paging = useMemo(() => {
    const length = data?.length || 0;
    return data?.[length - 1]?.paging;
  }, [data]);

  const loading = useMemo<boolean>(() => {
    if (!data?.length) {
      return true;
    }
    if (isLoading) {
      return true;
    }
    return false;
  }, [isValidating, searchValue, router.query]);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setTerm(event.target.value);
  };

  const showAll = () => {
    const length = data?.length || 0;
    const newSize = data?.[length - 1]?.paging.pageCount || 1;
    setSize(newSize);
    const params = new URLSearchParams(router.query as Record<string, string>);
    params.delete('category');
    if (!newSize || newSize === 1) {
      params.delete('page');
    } else {
      params.set('page', `${newSize}`);
    }
    const query = params.toString();
    const path = router.asPath.split('?')[0];
    router.push(`${path}${query && `?${query}`}`, undefined, {
      shallow: true,
    });
  };

  const loadMore = () => {
    const newSize = size + 1;
    setSize(newSize);
    const params = new URLSearchParams(router.query as Record<string, string>);
    params.delete('category');
    if (!newSize || newSize === 1) {
      params.delete('page');
    } else {
      params.set('page', `${newSize}`);
    }
    const query = params.toString();
    const path = router.asPath.split('?')[0];
    router.push(`${path}${query && `?${query}`}`, undefined, {
      shallow: true,
    });
  };

  const onSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    setSearchValue(term);
  };

  const clear = () => {
    setTerm('');
  };

  useEffect(() => {
    const queryTerm = router.query['q']?.toString().trim();

    if (queryTerm && !searchValue) {
      setSearchValue(router.query['q'] as string);
      setTerm(router.query['q'] as string);
    }

    if (searchValue && queryTerm !== searchValue) {
      const pathname = router.asPath.split('?')[0];
      const params = new URLSearchParams(
        router.query as Record<string, string>,
      );
      params.set('q', searchValue);
      params.delete('page');
      const query = params.toString();
      router.push({ pathname, query }, undefined, {
        shallow: true,
      });
      setSize(1);
    }
  }, [searchValue, router.query]);

  const message = useMemo<string>(() => {
    if (searchValue && !loading && data?.length) {
      const length = data?.length || 0;
      return (
        shared?.searchResultCount
          ?.replace('$1', `${data?.[length - 1]?.paging?.itemCount}`)
          .replace('$2', searchValue) || ''
      );
    } else if (
      searchValue &&
      !loading &&
      !data?.flatMap(({ items }) => items)?.length
    ) {
      return shared?.noSearchResults || '';
    }
    return '';
  }, [searchValue, loading, data, shared]);

  return (
    <>
      {' '}
      <SearchResultHero
        message={message}
        searchResultsPageTitle={shared.searchResultsPageTitle ?? ''}
      />
      <SearchInput
        value={term ?? ''}
        onSubmit={onSubmit}
        onChange={onChange}
        onClear={clear}
        placeholder={shared?.searchProducts || ''}
      />
      {loading && <Loader />}
      <Section>
        {!loading && !!data?.length && (
          <>
            <Grid
              columnGap={[
                { columnGap: 15 },
                { breakpoint: 'desktop', columnGap: 30 },
              ]}
              columns={[
                { columns: 1 },
                { breakpoint: 'mediumMobile', columns: 2 },
                { breakpoint: 'mobileMax', columns: 3 },
                { breakpoint: 'desktop', columns: 3 },
              ]}
            >
              <Products
                products={products}
                rrpLabel={shared?.rrpLabel || ''}
                rrpIncludingVatLabel={shared?.rrpIncludingVatLabel || ''}
              />
            </Grid>

            <Pager
              ofText={genericCategory.of ?? ''}
              productsText={genericCategory.products ?? ''}
              loadMore={loadMore}
              showAll={showAll}
              showAllText={shared.viewAll ?? ''}
              showMoreText={shared.viewMore ?? ''}
              pagerData={paging}
              loading={isValidating}
            />
          </>
        )}
      </Section>
    </>
  );
};
