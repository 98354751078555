import Link from 'next/link';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

export const PagePlugsStyled = styled.div`
  padding: ${spacing.small};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${spacing.medium};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-block: ${spacing.medium};
    padding-inline: ${spacing.large};
  }

  .Text {
    padding-block-start: ${spacing.xsmall};
  }
`;

export const PagePlugStyled = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${spacing.xxsmall};
`;

export const StyledLink = styled(Link)`
  font-size: ${fontSizes.header4};
  line-height: ${lineHeights.header4};
  font-weight: ${fontWeights.fontMedium};
  font-family: ${fontFamilies.fontHero};
  color: ${colors.black};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &:after {
    content: '';
    position: absolute;
    inset: 0;
  }
`;
