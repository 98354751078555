import styled, { css } from 'styled-components';

import { colors, spacing } from '@hultafors/hellberg/helpers';

export const TextBlockStyled = styled.div<{ $hasMarkDown?: boolean }>`
  max-width: calc(100vw - 40px);
  width: 1000px;
  margin-block: ${spacing.regular};
  margin-inline: auto;
  scroll-margin: var(--scroll-margin);

  h2,
  h3 {
    margin-block: ${spacing.small};
  }

  h4 {
    margin-block: ${spacing.tiny};
  }

  :first-child {
    margin-block-start: 72px;
  }

  ${({ $hasMarkDown }) =>
    $hasMarkDown &&
    css`
      p:not(:last-child) {
        margin-block-end: ${spacing.regular};
      }

      a {
        text-decoration: none;
        text-decoration: underline;
        color: ${colors.black};
      }
    `}
`;
