import { ProductAttribute } from '@hultafors/shared/types';

import { ListValue } from '@hultafors/hellberg/types';

import { HTag } from '../HTag/HTag';
import { Paragraph } from '../Paragraph/Paragraph';

import { Section, StyledTechnicalDataBox } from './TechnicalDataBox.styled';

interface TechnicalDataBoxProps {
  technicalDataLabel: string;
  list: ListValue[];
  className?: string;
  snr?: boolean;
}

export const TechnicalDataBox: React.FC<TechnicalDataBoxProps> = ({
  technicalDataLabel,
  list,
  className,
  snr,
}) => {
  const listMapper = (item: ProductAttribute) => {
    return (
      <Section key={`TechnicalDataBox-${item.label}-${item.value}`}>
        <Paragraph styleType="body14Medium">{item.label}</Paragraph>
        <Paragraph styleType="body16">{item.value}</Paragraph>
      </Section>
    );
  };

  return (
    <StyledTechnicalDataBox className={className} $snr={snr}>
      <HTag type="h2" styleType="header4">
        {technicalDataLabel}
      </HTag>
      {list.map(listMapper)}
    </StyledTechnicalDataBox>
  );
};
