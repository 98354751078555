// import slugify from 'slugify';
import { SitemapProduct } from '@hultafors/shared/types';

import {
  HellbergProduct,
  HellbergProductDetails,
  ParsedProduct,
} from '@hultafors/hellberg/types';

import { routes } from './constants';
import { slugify } from './stringHandling';

// TODO verify if slugify library creates identical slugs to old code
export const createDetailPageUrl = ({
  parentCategory,
  category,
  id,
  name,
  sku,
}:
  | HellbergProduct
  | HellbergProductDetails
  | ParsedProduct
  | SitemapProduct) => {
  return (
    '/' +
    [
      routes.PRODUCTS,
      slugify(parentCategory, '-'),
      slugify(category, '-'),
      `${sku}-${slugify(name, '_')}`,
      id.trim(),
    ].join('/')
  );
};
