import { useEffect, useId, useRef } from 'react';

import clsx from 'clsx';
import Image from 'next/image';
import { Swiper } from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

import { useGlobal } from '@hultafors/hellberg/hooks';
import { ImageFragment, ImageSliderFragment } from '@hultafors/hellberg/types';

import ArrowRight from '../../svg/arrow_right.svg';
import { Paragraph } from '../Paragraph/Paragraph';

import styles from './image-slider.module.scss';

require('swiper/css');
require('swiper/css/navigation');

type ImageSliderProps = ImageSliderFragment;
// TODO update this component to use shared image-slider
export const ImageSlider: React.FC<ImageSliderProps> = ({
  images = [],
  scrollId,
  small,
}) => {
  const uid = useId();
  const { shared } = useGlobal();
  const swiper = useRef<Swiper | null>(null);
  const swiperRef = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);

  const sanitizedScrollId = scrollId?.replace(/[^a-zA-Z0-9-_:.]/g, '');

  useEffect(() => {
    if (swiperRef.current && !swiper.current) {
      swiper.current = new Swiper(swiperRef.current, {
        direction: 'horizontal',
        loop: true,
        modules: [Navigation, Pagination],
        navigation: {
          nextEl: nextRef.current,
          prevEl: prevRef.current,
        },
        pagination: {
          el: paginationRef.current,
          type: 'fraction',
        },
      });
    }
  }, []);

  function slideMapper(image: ImageFragment, index: number) {
    if (!image.responsiveImage?.src) {
      return null;
    }
    return (
      <div
        key={`ImageSlider-${uid}-image-${index}`}
        className="swiper-slide"
        style={{ aspectRatio: image.responsiveImage.aspectRatio }}
      >
        <Image src={image.responsiveImage.src} fill alt={image.alt || ''} />
      </div>
    );
  }

  if (!images.length) {
    return null;
  }

  return (
    <section
      className={clsx(styles['image-slider'], small && styles['small'])}
      id={sanitizedScrollId || undefined}
    >
      <div className="swiper" ref={swiperRef}>
        <div className="swiper-wrapper">{images.map(slideMapper)}</div>
        <div className={styles['navigation']}>
          <button
            className={clsx(
              'button-reset',
              styles['navigation-button'],
              styles['flip'],
            )}
            ref={prevRef}
            aria-label={shared.previous || ''}
          >
            <ArrowRight
              aria-hidden={true}
              focusable={false}
              width={16}
              height={16}
            />
          </button>
          <Paragraph styleType="body16">
            <span ref={paginationRef} />
          </Paragraph>
          <button
            ref={nextRef}
            aria-label={shared.next || ''}
            className={clsx('button-reset', styles['navigation-button'])}
          >
            <ArrowRight
              aria-hidden={true}
              focusable={false}
              width={16}
              height={16}
            />
          </button>
        </div>
      </div>
    </section>
  );
};
