import dynamic from 'next/dynamic';
import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hellberg/helpers';

const PlainButton = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.PlainButton),
);

export const ProductImageDialogStyled = styled.aside`
  display: flex;
  flex-direction: column;
  gap: ${spacing.regular};
  padding-block: ${spacing.regular};
`;
export const DialogImage = styled.div`
  width: 100%;
  margin-block-end: ${spacing.regular};
  aspect-ratio: 1 / 1;
  background-color: ${colors.gray6};
  position: relative;
  max-block-size: 100vh;

  img {
    object-fit: contain;
  }
`;

export const CloseButton = styled(PlainButton)`
  cursor: pointer;
`;
