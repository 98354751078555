import { ImageFragment } from '@hultafors/hellberg/types';

import { HTag } from '../HTag/HTag';
import { Paragraph } from '../Paragraph/Paragraph';
import { SquareImage } from '../SquareImage/SquareImage';

import { ContentPlugStyled } from './ContentPlug.styled';

interface ContentPlugProps {
  title: string | null;
  url: string | null;
  image?: ImageFragment | null;
  text?: string | null;
  ctaText?: string | null;
  className?: string | null;
}

export const ContentPlug: React.FC<ContentPlugProps> = ({
  title = '',
  url = '',
  image,
  text = '',
  ctaText = '',
  className = '',
}) => {
  return (
    <ContentPlugStyled
      href={url || ''}
      className={className || undefined}
      data-test="contentPlug"
    >
      {image ? (
        <SquareImage image={image} />
      ) : (
        <div className="NoImagePlaceholder" />
      )}
      <div className="TextBoxWrapper">
        <HTag type="h2" styleType="header4" className="TextBoxTitle">
          {title}
        </HTag>
        <Paragraph styleType="body16" className="TextBoxText">
          {text ? text : '\u00A0'}
        </Paragraph>
        <div className="TextBoxCTAWrapper">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 12L15 8L11 4"
              stroke="#111111"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 8L1 8"
              stroke="#111111"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          {ctaText && (
            <Paragraph styleType="body16" className="TextBoxCTA">
              {ctaText}
            </Paragraph>
          )}
        </div>
      </div>
    </ContentPlugStyled>
  );
};
