import { useId } from 'react';

import clsx from 'clsx';
import dynamic from 'next/dynamic';

import {
  ContentAreaField,
  isAppLinksBlock,
  isBackLinkBlock,
  isBigPlugBlock,
  isBlueInfoBox,
  isBulletList,
  isColorPlugsBlock,
  isContactInformationBlock,
  isDocumentBlockNew,
  isDotdigitalEmbed,
  isDotDigitalForm,
  isDualTextBlock,
  isExploreAreaBlock,
  isGreyInfoBox,
  isHeroBlock,
  isImageBlock,
  isImageSlider,
  isLinkBoxBlock,
  isModelFeatureTableBlock,
  isOfficesBlock,
  isPageLinkListBlock,
  isPagePlugsBlock,
  isQuote,
  isRelatedProductsBlock,
  isSimpleTitleAndTextBlock,
  isSliderBlock,
  isSplitHeroBlock,
  isTextBlock,
  isTwoImageBlock,
  isVideo,
  isVisibleSeo,
} from '@hultafors/hellberg/types';

import styles from './ContentArea.module.scss';

const AppLinksBlock = dynamic(() =>
  import('../app-links-block/app-links-block').then((mod) => mod.AppLinksBlock),
);
const BigPlugBlock = dynamic(() =>
  import('../BigPlugBlock/BigPlugBlock').then((mod) => mod.BigPlugBlock),
);
const BlueInfoBox = dynamic(() =>
  import('../BlueInfoBox/BlueInfoBox').then((mod) => mod.BlueInfoBox),
);
const BulletListBlock = dynamic(() =>
  import('../BulletListBlock/BulletListBlock').then(
    (mod) => mod.BulletListBlock,
  ),
);
const ColorPlugsBlock = dynamic(() =>
  import('../ColorPlugsBlock/ColorPlugsBlock').then(
    (mod) => mod.ColorPlugsBlock,
  ),
);
const ContactInformationBlock = dynamic(() =>
  import('../contact-information-block/contact-information-block').then(
    (mod) => mod.ContactInformationBlock,
  ),
);
const DocumentBlock = dynamic(() =>
  import('../document-block/document-block').then((mod) => mod.DocumentBlock),
);
const DotDigitalFormBlock = dynamic(() =>
  import('../DotDigitalFormBlock/DotDigitalFormBlock').then(
    (mod) => mod.DotDigitalFormBlock,
  ),
);
const DotdigitalEmbedBlock = dynamic(() =>
  import('../dotdigital-embed-block/dotdigital-embed-block').then(
    (mod) => mod.DotdigitalEmbedBlock,
  ),
);
const DualImageBlock = dynamic(() =>
  import('../DualImageBlock/DualImageBlock').then((mod) => mod.DualImageBlock),
);
const DualTextBlock = dynamic(() =>
  import('../DualTextBlock/DualTextBlock').then((mod) => mod.DualTextBlock),
);
const ExploreAreaBlock = dynamic(() =>
  import('../ExploreAreaBlock/ExploreAreaBlock').then(
    (mod) => mod.ExploreAreaBlock,
  ),
);
const GreyInfoBoxBlock = dynamic(() =>
  import('../GreyInfoBoxBlock/GreyInfoBoxBlock').then(
    (mod) => mod.GreyInfoBoxBlock,
  ),
);
const HeroBlock = dynamic(() =>
  import('../HeroBlock/HeroBlock').then((mod) => mod.HeroBlock),
);
const ImageBlock = dynamic(() =>
  import('../ImageBlock/ImageBlock').then((mod) => mod.ImageBlock),
);
const ImageSlider = dynamic(() =>
  import('../image-slider/image-slider').then((mod) => mod.ImageSlider),
);
const LinkBoxBlock = dynamic(() =>
  import('../LinkBoxBlock/LinkBoxBlock').then((mod) => mod.LinkBoxBlock),
);
const ModelFeatureTableBlock = dynamic(() =>
  import('../ModelFeatureTableBlock/ModelFeatureTableBlock').then(
    (mod) => mod.ModelFeatureTableBlock,
  ),
);
const OfficesBlock = dynamic(() =>
  import('../ContactStores/ContactStores').then((mod) => mod.ContactStores),
);
const PagePlugs = dynamic(() =>
  import('../PagePlugs/PagePlugs').then((mod) => mod.PagePlugs),
);
const QuoteBlock = dynamic(() =>
  import('../QuoteBlock/QuoteBlock').then((mod) => mod.QuoteBlock),
);
const RelatedProductsBlock = dynamic(() =>
  import('../RelatedProductsBlock/RelatedProductsBlock').then(
    (mod) => mod.RelatedProductsBlock,
  ),
);
const SeoSection = dynamic(() =>
  import('../SeoSection/SeoSection').then((mod) => mod.SeoSection),
);
const SimpleTitleAndTextBlock = dynamic(() =>
  import('../simple-title-and-text-block/simple-title-and-text-block').then(
    (mod) => mod.SimpleTitleAndTextBlock,
  ),
);
const SliderBlock = dynamic(() =>
  import('../SliderBlock/SliderBlock').then((mod) => mod.SliderBlock),
);
const SplitHeroBlock = dynamic(() =>
  import('../SplitHeroBlock/SplitHeroBlock').then((mod) => mod.SplitHeroBlock),
);
const TextBlock = dynamic(() =>
  import('../TextBlock/TextBlock').then((mod) => mod.TextBlock),
);
const VideoBlock = dynamic(() =>
  import('../video-block/video-block').then((mod) => mod.VideoBlock),
);
const PageLinkListBlock = dynamic(() =>
  import('../page-link-list-block/page-link-list-block').then(
    (mod) => mod.PageLinkListBlock,
  ),
);
const BackLinkBlock = dynamic(() =>
  import('../back-link-block/back-link-block').then((mod) => mod.BackLinkBlock),
);

interface ContentAreaProps {
  content: ContentAreaField[];
  largeMargin?: boolean;
}

export const ContentArea: React.FC<ContentAreaProps> = ({
  content,
  largeMargin,
}) => {
  const uid = useId();

  const contentMapper = (item: ContentAreaField, index: number) => {
    const key = `Block-${uid}-${item.__typename}-${index}`;
    const priority = index === 0;

    if (isAppLinksBlock(item)) {
      return <AppLinksBlock {...item} key={key} />;
    }
    if (isBigPlugBlock(item)) {
      return <BigPlugBlock {...item} key={key} />;
    }
    if (isBlueInfoBox(item)) {
      return <BlueInfoBox key={key} {...item} />;
    }
    if (isBulletList(item)) {
      return <BulletListBlock {...item} key={key} />;
    }
    if (isColorPlugsBlock(item)) {
      return <ColorPlugsBlock {...item} key={key} />;
    }
    if (isContactInformationBlock(item)) {
      return <ContactInformationBlock {...item} key={key} />;
    }
    if (isDocumentBlockNew(item)) {
      return <DocumentBlock {...item} key={key} inContentArea />;
    }
    if (isDotDigitalForm(item)) {
      return <DotDigitalFormBlock {...item} key={key} />;
    }
    if (isDotdigitalEmbed(item)) {
      return <DotdigitalEmbedBlock {...item} key={key} />;
    }
    if (isDualTextBlock(item)) {
      return <DualTextBlock {...item} key={key} />;
    }
    if (isExploreAreaBlock(item)) {
      return <ExploreAreaBlock {...item} key={key} isContentArea />;
    }
    if (isGreyInfoBox(item)) {
      return <GreyInfoBoxBlock {...item} key={key} />;
    }
    if (isHeroBlock(item)) {
      return <HeroBlock {...item} key={key} priority={priority} />;
    }
    if (isImageBlock(item)) {
      return <ImageBlock {...item} key={key} />;
    }
    if (isImageSlider(item)) {
      return <ImageSlider {...item} key={key} />;
    }
    if (isLinkBoxBlock(item)) {
      return <LinkBoxBlock {...item} key={key} />;
    }
    if (isModelFeatureTableBlock(item)) {
      return <ModelFeatureTableBlock {...item} key={key} />;
    }
    if (isOfficesBlock(item)) {
      return <OfficesBlock {...item} key={key} />;
    }
    if (isPagePlugsBlock(item)) {
      return <PagePlugs {...item} key={key} />;
    }
    if (isQuote(item)) {
      return <QuoteBlock {...item} key={key} />;
    }
    if (isRelatedProductsBlock(item)) {
      return <RelatedProductsBlock {...item} key={key} />;
    }
    if (isSimpleTitleAndTextBlock(item)) {
      return (
        <SimpleTitleAndTextBlock {...item} key={key} priority={priority} />
      );
    }
    if (isSliderBlock(item)) {
      return <SliderBlock {...item} key={key} />;
    }
    if (isSplitHeroBlock(item)) {
      return <SplitHeroBlock {...item} key={key} priority={priority} />;
    }
    if (isTextBlock(item)) {
      return <TextBlock {...item} key={key} />;
    }
    if (isTwoImageBlock(item)) {
      return <DualImageBlock {...item} key={key} />;
    }
    if (isVideo(item)) {
      return <VideoBlock {...item} key={key} />;
    }
    if (isVisibleSeo(item)) {
      return <SeoSection {...item} key={key} />;
    }
    if (isPageLinkListBlock(item)) {
      return <PageLinkListBlock {...item} key={key} />;
    }
    if (isBackLinkBlock(item)) {
      return <BackLinkBlock {...item} key={key} />;
    }
    return null;
  };

  return (
    <div
      className={clsx(
        styles['content-area'],
        largeMargin && styles['large-margin'],
      )}
    >
      {content.map(contentMapper)}
    </div>
  );
};
