import styled, { css, CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hellberg/helpers';

interface BigPlugBlockStyledProps {
  $fullWidth: boolean;
  $flipSide: boolean;
  $background?: CSSProperties['background'];
}

export const BigPlugBlockStyled = styled.div<BigPlugBlockStyledProps>`
  margin-block: ${spacing.large};
  display: flex;
  background-color: ${({ $background }) => $background};
  flex-direction: column;
  padding-block: ${spacing.medium};
  padding-inline: ${spacing.small};

  ${({ $fullWidth }) =>
    !$fullWidth &&
    css`
      max-width: 1440px;
      margin-inline: auto;
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${spacing.medium};
    flex-direction: ${({ $flipSide }) => ($flipSide ? 'row-reverse' : 'row')};
  }
`;

export const ContentWrapper = styled.div`
  align-self: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  place-content: center center;
  align-items: flex-start;
  margin-block: ${spacing.medium};
  margin-inline: 0;
  padding-block: 0;
  padding-inline: ${spacing.regular};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-block: 0;
    padding-inline: ${spacing.medium};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: 0;
    inline-size: 80%;
  }

  h2 {
    padding-block-end: ${spacing.regular};
  }
`;
