import React from 'react';

import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';

import { ColorListTypes } from '@hultafors/shared/types';

import { createDetailPageUrl } from '@hultafors/hellberg/helpers';

import styles from './detailsViewProductColors.module.scss';

type DetailsViewProductColorsProps = {
  colors: ColorListTypes[];
  productCategory: string;
  productParentCategory: string;
};

export const DetailsViewProductColors: React.FC<
  DetailsViewProductColorsProps
> = ({ colors = [], productCategory, productParentCategory }) => {
  if (!Array.isArray(colors) || !colors.length) {
    return null;
  }

  const colorMapper = (color: ColorListTypes) => {
    const id = color.id ? color.id : color.detailsPage?.url?.split('=')[1];
    const productUrl = createDetailPageUrl({
      category: productCategory,
      id: id || '',
      name: color.name || '',
      parentCategory: productParentCategory,
      sku: color.sku || '',
    });

    return (
      <li key={`product-color-${color.text}`} className={styles['list-item']}>
        <Link href={`${productUrl}`}>
          <Image
            src={color.image?.url || ''}
            alt={color.text || ''}
            fill
            className={clsx(
              styles['color'],
              colors[0] === color && styles['color--active'],
            )}
          />
        </Link>
      </li>
    );
  };

  return (
    <div>
      <ul className={styles['list']}>{colors.map(colorMapper)}</ul>
    </div>
  );
};
