import styled, { css } from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

interface LinkButtonStyledProps {
  $fullWidth?: boolean;
  $black?: boolean;
}

export const LinkButtonStyled = styled.div<LinkButtonStyledProps>`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  padding-block: 0;
  padding-inline: ${spacing.xsmall};
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.2s;

  a {
    display: flex;
    block-size: 100%;
    align-items: center;
    justify-content: center;
    font-size: ${fontSizes.body14};
    line-height: ${lineHeights.body14};
    font-weight: ${fontWeights.fontMedium};
    font-family: ${fontFamilies.fontRegularBold};
    color: ${colors.black};
    text-decoration: none;
    text-align: center;
  }

  ${({ $black }) =>
    $black &&
    css`
      border: 1px solid ${colors.black};
      background-color: ${colors.black};

      a,
      a:hover {
        color: ${colors.white};
      }

      &:hover {
        border: 1px solid ${colors.blackHover};
        background-color: ${colors.blackHover};
      }
    `}

  svg {
    color: ${colors.white};
    margin-inline-end: ${spacing.small};
    block-size: 100%;
  }
`;
