import { HellbergProductDetails } from '@hultafors/hellberg/types';

import { HTag } from '../HTag/HTag';

import {
  Info,
  IsNewLabel,
  ModelSkuLabel,
  PriceWrapper,
  ProductNameWrapper,
  RRPCurrency,
  RRPLabel,
  StyledProductDetailData,
} from './ProductDetailData.styled';

interface ProductDetailDataProps {
  product: HellbergProductDetails;
  modelSku?: string;
  modelSkuLabel: string;
  rrpLabel: string;
  rrpIncludingVatLabel: string;
  newLabel: string;
  isNew: boolean;
}

export const ProductDetailData: React.FC<ProductDetailDataProps> = ({
  product,
  modelSku,
  isNew,
  modelSkuLabel,
  rrpLabel,
  rrpIncludingVatLabel,
  newLabel,
}) => {
  return (
    <StyledProductDetailData>
      <Info>
        {isNew && <IsNewLabel>{newLabel}</IsNewLabel>}
        <ProductNameWrapper>
          {product.name && (
            <HTag type="h1" styleType="header4">
              {product.name}
            </HTag>
          )}
        </ProductNameWrapper>
        {product.id && (
          <ModelSkuLabel>{`${modelSkuLabel} ${modelSku}`}</ModelSkuLabel>
        )}
        {product.price && product.price > 0 ? (
          <PriceWrapper>
            <RRPCurrency>{`${product.currency} ${product.price}`}</RRPCurrency>
            <RRPLabel>{rrpLabel}</RRPLabel>
          </PriceWrapper>
        ) : product?.priceWithVat && product.priceWithVat > 0 ? (
          <PriceWrapper>
            <RRPCurrency>{`${product.currency} ${product.priceWithVat}`}</RRPCurrency>
            <RRPLabel>{rrpIncludingVatLabel}</RRPLabel>
          </PriceWrapper>
        ) : (
          ''
        )}
      </Info>
    </StyledProductDetailData>
  );
};
