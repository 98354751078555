import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hellberg/helpers';

export const BlueInfoBoxStyled = styled.div`
  max-width: calc(100vw - 40px);
  width: 1000px;
  margin-inline: auto;
  margin-block: ${spacing.regular};
  scroll-margin: var(--scroll-margin);
`;

export const Content = styled.div`
  background-color: ${colors.hellBlue};
  color: ${colors.white};

  h2 {
    color: ${colors.white};
    padding: ${spacing.medium};
  }

  a {
    color: inherit;
  }
`;
