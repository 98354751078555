import { useMemo } from 'react';

import { ColorListTypes, ProductDetailsValue } from '@hultafors/shared/types';

import {
  AppLinksBlockFragment,
  CategoryFeatureFragment,
} from '@hultafors/hellberg/types';

import { AppLinksBlock } from '../app-links-block/app-links-block';
import { DetailsViewProductColors } from '../DetailsViewProductColors/DetailsViewProductColors';
import { Features } from '../Features/Features';
import { ProductInformationTabs } from '../ProductInformationTabs/ProductInformationTabs';

import {
  FeatureList,
  ProductDetailInformationStyled,
  RetailersLink,
} from './ProductDetailInformation.styled';

interface ProductDetailInformationProps {
  addToShoppingListButtonLabel?: string;
  overviewLabel: string;
  documentsLabel: string;
  detailsLabel: string;
  intro: string;
  // documents: ProductDetailsApiDocument[];
  detailsInfo: string;
  features: ProductDetailsValue[];
  featuresLabel: string;
  productForStorage: any;
  usp: string[];
  appInformation: AppLinksBlockFragment | null;
  retailersLabel: string;
  productId: string;
  productCategory: string;
  productParentCategory: string;
  colors?: ColorListTypes[];
}

export const ProductDetailInformation: React.FC<
  ProductDetailInformationProps
> = ({
  appInformation,
  detailsInfo,
  detailsLabel,
  // documents,
  documentsLabel,
  features: featuresInput,
  featuresLabel,
  intro,
  overviewLabel,
  productForStorage,
  usp,
  retailersLabel,
  productId,
  productParentCategory,
  productCategory,
  // models,
  colors,
}) => {
  const featuresFilter = (feature: ProductDetailsValue) => {
    if (
      !feature.text ||
      (typeof feature.text === 'string' && feature.text.trim().length === 0) ||
      feature.image === null ||
      feature.image === undefined
    ) {
      return null;
    }

    return feature;
  };

  const features: CategoryFeatureFragment[] = useMemo(() => {
    return (
      featuresInput.filter(featuresFilter).map((feature) => {
        return {
          icon: {
            alt: feature?.image?.description || '',
            format: feature?.image?.format || '',
            tags: [],
            url: feature?.image?.url || '',
          },
          name: feature.text,
        };
      }) || []
    );
  }, [featuresInput]);

  return (
    <ProductDetailInformationStyled>
      {!!usp?.length && (
        <FeatureList>
          {usp.filter(Boolean).map((item, i) => (
            <li key={`uspKey${i}`}>{item}</li>
          ))}
        </FeatureList>
      )}
      {colors && !!colors.length && (
        <DetailsViewProductColors
          colors={colors}
          productParentCategory={productParentCategory}
          productCategory={productCategory}
        />
      )}
      {retailersLabel && (
        <span className="ChooseSizeButton">
          <RetailersLink href="/retailers">{retailersLabel}</RetailersLink>
        </span>
      )}
      <ProductInformationTabs
        overviewLabel={overviewLabel}
        documentsLabel={documentsLabel}
        detailsLabel={detailsLabel}
        intro={intro}
        detailsInfo={detailsInfo}
        productId={productId}
      />
      {appInformation && (
        <AppLinksBlock
          {...appInformation}
          styles={{
            inlineSize: '100%',
            marginBlockEnd: '48px',
            maxInlineSize: 'unset',
          }}
        />
      )}
      {features.length > 0 && (
        <Features title={featuresLabel} features={features} />
      )}
    </ProductDetailInformationStyled>
  );
};
