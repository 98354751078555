import styled, { css } from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  spacing,
} from '@hultafors/hellberg/helpers';

import { Paragraph } from '../Paragraph/Paragraph';
import { TextButton } from '../TextButton/TextButton';

export const Tabs = styled.div<{ $center?: boolean }>`
  display: flex;
  text-align: center;
  margin-block-end: ${spacing.regular};
  ${({ $center }) =>
    $center &&
    css`
      justify-content: center;
    `}
`;
export const Tab = styled(TextButton)<{ $active?: boolean }>`
  margin-inline-end: ${spacing.small};

  p {
    margin: 0 0 ${spacing.xsmall} 0;
    color: ${colors.gray0};
  }

  margin-block-end: 1px;

  ${({ $active }) =>
    $active &&
    css`
      margin-block-end: 0;
      border-block-end: 1px solid ${colors.hellBlue};

      p {
        color: ${colors.hellBlue};
      }
    `}
`;
export const OverviewText = styled(Paragraph).attrs({ styleType: 'body16' })`
  margin: 0;
`;
export const DetailInfo = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    font-size: ${fontSizes.body16};
    font-family: ${fontFamilies.fontRegular};
  }
`;

export const ProductInformationTabsStyled = styled.div`
  margin-block-end: ${spacing.medium};

  button {
    padding: 0;
  }

  a:hover {
    cursor: pointer;
  }
`;
