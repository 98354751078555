import { Fragment, useState } from 'react';

import dynamic from 'next/dynamic';
import Image from 'next/image';

import { useGlobal } from '@hultafors/hellberg/hooks';
import { ImageBasicFragment } from '@hultafors/hellberg/types';

import {
  AppBtn,
  AppLink,
  BadgesContainer,
  BannerStyled,
  DownloadAppsContainer,
  DownloadLabel,
  ImageWrapper,
  Inner,
  PopupContent,
  PopupImageContainer,
  TextContent,
} from './banner.styled';

const Popup = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.Popup),
);

const TextBlock = dynamic(() =>
  import('../TextBlock/TextBlock').then((mod) => mod.TextBlock),
);

interface AppLinkProps {
  image?: ImageBasicFragment | null;
  url?: string;
  deviceType: string;
}

export const Banner = () => {
  const [isOpen, setIsOpen] = useState<boolean | null>(false);
  const [deviceType, setDeviceType] = useState<string | null>(null);

  const {
    shared: {
      downloadAppLabel,
      bannerDescription,
      bannerImage,
      bannerTitle,
      popupImage,
      appstoreBadge,
      appstoreUrl,
      googlePlayBadge,
      googlePlayUrl,
      popupBodyText,
      popupHeader,
      googlePlayQr,
    },
  } = useGlobal();

  const appLinks: AppLinkProps[] = [
    { deviceType: 'IPhone', image: appstoreBadge, url: appstoreUrl || '' },
    { deviceType: 'Android', image: googlePlayBadge, url: googlePlayUrl || '' },
  ];

  let qrImage = popupImage;

  if (deviceType === 'Android') {
    qrImage = googlePlayQr;
  }

  function toggleOpen(type: string | null) {
    setIsOpen(true);
    setDeviceType(type);
  }

  function toggleClose() {
    setIsOpen(false);
    setDeviceType(null);
  }

  function badgeMapper(badge: AppLinkProps, index: number) {
    if (!badge || !badge?.image || !badge?.url) {
      return null;
    }
    return (
      <Fragment key={`app-btn-${index}`}>
        <AppBtn
          role="button"
          key={`app-btn-${index}`}
          onClick={() => toggleOpen(badge.deviceType)}
        >
          <Image
            src={badge?.image?.url || ''}
            alt={badge?.image?.alt || ''}
            style={{ objectFit: 'contain' }}
            width={154}
            height={52.8}
          />
        </AppBtn>
        <AppLink href={badge?.url || ''}>
          <Image
            src={badge?.image?.url || ''}
            alt={badge?.image?.alt || ''}
            style={{ objectFit: 'contain' }}
            width={154}
            height={52.8}
          />
        </AppLink>
      </Fragment>
    );
  }

  if (!bannerImage || !bannerTitle) {
    return null;
  }

  return (
    <BannerStyled>
      <Inner>
        {bannerImage && (
          <ImageWrapper
            $aspectRatio={`${bannerImage?.responsiveImage?.aspectRatio}`}
          >
            <Image
              src={bannerImage.responsiveImage?.src || ''}
              alt={bannerImage?.alt || ''}
              fill
              style={{ objectFit: 'contain' }}
            />
          </ImageWrapper>
        )}

        {appstoreUrl && googlePlayUrl && (
          <DownloadAppsContainer>
            {bannerTitle && bannerDescription && (
              <TextContent title={bannerTitle} body={bannerDescription} />
            )}
            {downloadAppLabel && (
              <DownloadLabel>{downloadAppLabel}</DownloadLabel>
            )}
            {appLinks?.length && (
              <BadgesContainer>{appLinks?.map(badgeMapper)}</BadgesContainer>
            )}
          </DownloadAppsContainer>
        )}
      </Inner>
      <Popup enterFrom="bottom" isOpen={!!isOpen} toggle={toggleClose}>
        <PopupContent>
          {popupHeader && popupBodyText && (
            <TextBlock title={popupHeader} body={popupBodyText} />
          )}
          {qrImage && (
            <PopupImageContainer>
              <Image
                src={qrImage?.url}
                alt={qrImage?.alt || `${deviceType ?? 'App'} QR`}
                fill
                style={{ borderRadius: '25px' }}
              />
            </PopupImageContainer>
          )}
        </PopupContent>
      </Popup>
    </BannerStyled>
  );
};
