import { ProductsSkuFragment } from '@hultafors/hellberg/types';

export function getGroupProductSkus(
  input: ProductsSkuFragment[] | null | undefined,
): string | undefined {
  return input
    ?.map(({ skuList }) => [
      ...new Set(
        skuList?.split(',')?.map((sku) => sku.toLowerCase().trim()) || [],
      ),
    ])
    .join('_');
}
