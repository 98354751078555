import { Component } from 'react';

import Close from '../../svg/close.svg';
import PartnerLogin from '../../svg/partner_login.svg';
import Trash from '../../svg/trash.svg';
import { Paragraph } from '../Paragraph/Paragraph';
import { TextButton } from '../TextButton/TextButton';

import {
  Clear,
  CloseBtn,
  CloseButton,
  Extras,
  HeaderIcon,
  HeaderText,
  SlideInBackground,
  SlideInBody,
  SlideInExit,
  SlideInHeader,
  SlideInInside,
} from './SlideIn.styled';
interface SlideInProps {
  isOpen: boolean;
  toggle: (...args: any[]) => any;
  headerText?: string;
  headerExtra?: string;
  headerIcon?: string;
  headerClick?: (...args: any[]) => any;
  clearText?: string;
  clearAll?: (...args: any[]) => any;
  padContent?: boolean;
  isMenu?: boolean;
  isCart?: boolean;
  isFilter?: boolean;
  fromLeft?: boolean;
  fromTop?: boolean;
  small?: boolean;
  partnerPortalUrl?: string;
  partnerLabel?: string;
  iconLeft?: boolean;
  isSearch?: boolean;
  lastType?: string;
  noHeader?: boolean;
  closeLabel?: string;
  className?: string;
  itemLength?: number;
  children?: React.ReactNode;
}
export class SlideIn extends Component<SlideInProps> {
  node: HTMLElement | null = null;
  setNode: React.Ref<HTMLDivElement> = (element) => {
    this.node = element;
  };

  constructor(props: SlideInProps) {
    super(props);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener('keydown', this.handleOnKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    document.removeEventListener('keydown', this.handleOnKeyDown, false);
  }

  handleClick(e: MouseEvent) {
    if (this.node === e.target) {
      this.props.toggle?.();
    }
  }

  handleOnKeyDown(e: KeyboardEvent) {
    // esc key
    if (e.keyCode === 27) {
      if (this.props.isOpen) {
        this.props.toggle?.();
      }
    }
  }

  render() {
    const {
      isOpen,
      toggle,
      children,
      headerText,
      headerExtra,
      clearText,
      clearAll,
      headerIcon,
      isMenu,
      partnerPortalUrl,
      partnerLabel,
      itemLength,
      headerClick,
      iconLeft,
      lastType,
      noHeader,
      closeLabel,
      isFilter,
      fromTop,
      fromLeft,
      isCart,
      isSearch,
      small,
      padContent,
    } = {
      ...this.props,
    };

    const HeaderTextContent = () => {
      if (isMenu) {
        return (
          <>
            <CloseButton aria-label={closeLabel || 'close'} onClick={toggle}>
              <Close
                aria-hidden={true}
                focusable={false}
                width={24}
                height={24}
              />
            </CloseButton>
            {partnerPortalUrl && (
              <div>
                <Paragraph>
                  <a
                    href={partnerPortalUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {partnerLabel}
                    <PartnerLogin
                      aria-hidden={true}
                      focusable={false}
                      width={16}
                      height={16}
                    />
                  </a>
                </Paragraph>
              </div>
            )}
          </>
        );
      }
      const IsNotMenu = () => {
        if (headerClick) {
          return (
            <TextButton
              iconLeft={iconLeft}
              iconUrl={headerIcon}
              onClick={headerClick}
            >
              <Paragraph styleType="body16Medium">{headerText}</Paragraph>
            </TextButton>
          );
        }
        return (
          <>
            {headerIcon && (
              <HeaderIcon src={headerIcon} alt={headerText ?? ''} />
            )}
            <Paragraph styleType="body16Medium">{headerText}</Paragraph>
            {headerExtra && <Extras>{headerExtra}</Extras>}
          </>
        );
      };
      return <IsNotMenu />;
    };

    const SlideInHeaderBlock = (): React.ReactNode => {
      return (
        <SlideInHeader $isFilter={isFilter}>
          <HeaderText $isMenu={isMenu}>
            <HeaderTextContent />
          </HeaderText>
          <SlideInExit>
            {clearAll && clearText && !!itemLength && (
              <Clear aria-label={clearText} onClick={clearAll}>
                <Paragraph styleType="body16">{clearText}</Paragraph>
                <Trash
                  aria-hidden={true}
                  focusable={false}
                  width={16}
                  height={16}
                />
              </Clear>
            )}
            {isMenu && <span />}
            {!isMenu && (
              <CloseBtn aria-label={closeLabel} onClick={toggle}>
                <Close
                  aria-hidden={true}
                  focusable={false}
                  width={24}
                  height={24}
                />
              </CloseBtn>
            )}
          </SlideInExit>
        </SlideInHeader>
      );
    };

    return (
      <div>
        <SlideInBackground
          $fromTop={fromTop}
          $active={isOpen}
          ref={this.setNode}
        >
          <SlideInInside
            $fromLeft={fromLeft}
            $fromTop={fromTop}
            $isFilter={isFilter}
            $isSearch={isSearch}
            $small={small}
            $active={isOpen}
            className={lastType}
          >
            {!noHeader && <SlideInHeaderBlock />}
            <SlideInBody
              $isCart={!!isCart}
              $padContent={padContent}
              $fromTop={fromTop}
              $isSearch={isSearch}
            >
              {children}
            </SlideInBody>
          </SlideInInside>
        </SlideInBackground>
      </div>
    );
  }
}
