import { useEffect, useId, useRef } from 'react';

import Script from 'next/script';

import { DotDigitalFormFragment } from '@hultafors/hellberg/types';

export const DotDigitalFormBlock: React.FC<DotDigitalFormFragment> = ({
  formUrl,
  scrollId,
}) => {
  const uid = useId();
  const id = `DotDigitalForm-${uid}`;
  const iframeWrapperRef = useRef<HTMLDivElement | null>(null);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const sanitizedScrollId = scrollId?.replace(/[^a-zA-Z0-9-_:.]/g, '');

  /*
  Ugly hack to make the form render in the footer if
  the form is not a popover
  */
  function appendFormToFooter() {
    /* Removed specific class querySelectors
    because classnames change in production
    */
    const footerMiddleSection = document.getElementById(
      'footer-middle-section',
    );
    const iframeElement = document.querySelector(
      '._lpSurveyEmbed',
    ) as HTMLIFrameElement;

    if (iframeElement) {
      const iframeWrapper = document.createElement('div');

      if (sanitizedScrollId) {
        iframeWrapper.setAttribute('id', sanitizedScrollId);
      }

      // Apply styles to iframe
      iframeElement.style.width = '100%';
      iframeElement.style.position = 'relative';

      // Apply styles to wrapper
      iframeWrapper.style.width = '100%';
      iframeWrapper.style.maxWidth = '600px';

      // Append to the footer
      iframeWrapper.appendChild(iframeElement);
      footerMiddleSection?.appendChild(iframeWrapper);

      iframeWrapperRef.current = iframeWrapper;
    }
  }

  useEffect(() => {
    return () => {
      document.querySelector('#lpclose')?.dispatchEvent(new Event('click'));
    };
  }, []);

  useEffect(() => {
    return () => {
      // Remove the iframe element from the footer when the component unmounts
      if (iframeWrapperRef.current) {
        iframeWrapperRef?.current.remove();
      }
    };
  }, []);

  if (!formUrl) {
    return null;
  }
  if (formUrl.includes('popover')) {
    return <Script src={formUrl} id={id} />;
  }

  return <Script src={formUrl} id={id} onReady={appendFormToFooter} />;
};
