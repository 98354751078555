import { useRef } from 'react';

import { MuxPlayerRefAttributes } from '@mux/mux-player-react/.';
import MuxPlayer from '@mux/mux-player-react/lazy';

import { useMuxPlayer } from '@hultafors/shared/hooks';

import { VideoFragment } from '@hultafors/hellberg/types';

import styles from './video-block.module.scss';

const MuxPlayerContent: React.FC<VideoFragment> = ({ video }) => {
  const videoEl = useRef<MuxPlayerRefAttributes | null>(null);
  const { playVideo, isPlaying, pauseVideo } = useMuxPlayer();

  const handleClick = () => {
    if (isPlaying === 'playing') {
      pauseVideo(videoEl);
    } else {
      playVideo(videoEl);
    }
  };

  return (
    <div
      onClick={
        isPlaying === 'playing'
          ? () => pauseVideo(videoEl)
          : () => playVideo(videoEl)
      }
    >
      <MuxPlayer
        ref={videoEl}
        playbackId={video?.video?.muxPlaybackId ?? undefined}
        placeholder={video?.blurUpThumb ?? undefined}
        streamType="on-demand"
        style={{
          aspectRatio: '16/9',
        }}
        playsInline
        className={styles['mux-player']}
      />
      {isPlaying !== 'playing' && (
        <button
          title="play"
          onClick={handleClick}
          className={styles['play-button']}
        >
          <svg
            fill="none"
            height="14"
            viewBox="0 0 12 14"
            width="12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m12 7-11.9999997 6.9282.00000061-13.8564036z" />
          </svg>
        </button>
      )}
    </div>
  );
};

export default MuxPlayerContent;
