import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hellberg/helpers';

interface SectionStyledProps {
  $hide?: boolean;
  $borderBottom?: boolean;
  $borderTop?: boolean;
  $fullWidth?: boolean;
  $largeMarginBottom?: boolean;
  $largeMarginTop?: boolean;
  $maxWidth?: boolean;
  $mediumMarginBottom?: boolean;
  $megaMarginBottom?: boolean;
  $noMargin?: boolean;
  $noMarginBottom?: boolean;
  $noMarginTop?: boolean;
  $smallMarginBottom?: boolean;
  $smallMarginTop?: boolean;
}
export const SectionStyled = styled.section<SectionStyledProps>`
  display: ${({ $hide }) => ($hide ? 'none' : 'block')};
  padding-block: ${spacing.medium};
  padding-inline: ${spacing.small};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-inline: ${spacing.medium};
  }

  ${({ $smallMarginTop }) =>
    $smallMarginTop &&
    css`
      padding-block-start: ${spacing.small};
    `}
  ${({ $largeMarginTop }) =>
    $largeMarginTop &&
    css`
      padding-block-start: ${spacing.large};
    `}
  ${({ $smallMarginBottom }) =>
    $smallMarginBottom &&
    css`
      padding-block-end: ${spacing.small};
    `}
  ${({ $mediumMarginBottom }) =>
    $mediumMarginBottom &&
    css`
      padding-block-end: ${spacing.regular};
    `}
  ${({ $largeMarginBottom }) =>
    $largeMarginBottom &&
    css`
      padding-block-end: ${spacing.large};
    `}
  ${({ $megaMarginBottom }) =>
    $megaMarginBottom &&
    css`
      padding-block-end: ${spacing.XL};
    `}
  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      padding-inline-end: 0;
    `}

  ${({ $borderTop }) =>
    $borderTop &&
    css`
      border-block-start: 1px solid ${colors.gray4};
    `}

  ${({ $borderBottom }) =>
    $borderBottom &&
    css`
      border-block-end: 1px solid ${colors.gray4};
    `}

  ${({ $noMarginBottom }) =>
    $noMarginBottom &&
    css`
      margin-block-end: 0;
    `}

  ${({ $noMarginTop }) =>
    $noMarginTop &&
    css`
      padding-block-start: 0;
    `}

  ${({ $noMargin }) =>
    $noMargin &&
    css`
      padding: 0;
    `}

  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${breakpoints.maxPageWidth};

      @media screen and (min-width: ${breakpoints.maxPageWidth}) {
        inline-size: 100%;
        margin-block: 0;
        margin-inline: auto;
        padding-inline: 0;
      }
    `}
`;
