import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hellberg/helpers';

import { Dialog } from '../Dialog/Dialog';
import { IframeContainer } from '../iframe-container/iframe-container';

export const BackDrop = styled.div`
  position: fixed;
  inset: 0;
  background-color: black;
  opacity: 0.3;
  z-index: 2;
`;

export const StyledDialog = styled(Dialog)`
  height: 80%;
  width: 90%;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    block-size: 65%;
    inline-size: 80%;
    max-block-size: 600px;
    max-inline-size: 1250px;
  }
`;

export const DialogContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  inset: 0;
  margin: auto;
  padding: 0;
  height: 80%;
  width: 100%;
  overflow: hidden;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-block: 0;
    padding-inline: ${spacing.medium};
  }
`;

export const ImageWrapper = styled.div`
  max-width: 400px;
  margin-inline-end: ${spacing.medium};

  @media screen and (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export const StyledIframeContainer = styled(IframeContainer)`
  min-width: 300px;
  overflow: hidden;
`;
