import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';
import { buttonReset } from '@hultafors/shared/helpers';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

export const ThumbNailWrapper = styled.div`
  display: none;

  img {
    object-fit: contain;
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    display: flex;
    flex-direction: column;
    gap: ${spacing.xsmall};
  }
`;

export const Thumbnail = styled.button<{ $active?: boolean }>`
  ${buttonReset};
  background-color: ${colors.gray5};
  position: relative;
  display: block;
  line-height: 0;
  cursor: pointer;
  border: thin solid
    ${({ $active }) => ($active ? colors.gray3 : 'transparent')};
`;

export const SwiperContainer = styled.div`
  background-color: ${colors.gray5};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${spacing.xsmall};
  overflow-x: hidden;
`;

export const SwiperWrapper = styled.div`
  aspect-ratio: 1 / 1;
`;

export const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: ${spacing.small};

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    grid-template-columns: 100px 1fr;
    padding: var(--header-height) 0 0 ${spacing.regular};
  }
`;

export const SwiperSlide = styled.div`
  position: relative;

  img {
    object-fit: contain;
  }
`;

export const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spacing.xsmall};
`;

export const Pagination = styled.div`
  text-align: center;
  pointer-events: none;
  font-size: ${fontSizes.body12};
  line-height: ${lineHeights.body12};
  padding-block-end: ${spacing.xxsmall};
`;

export const NavigationButton = styled.button<{ $flip?: boolean }>`
  ${buttonReset};
  display: none;
  position: absolute;
  inset-block-start: calc(50% - 24px);
  inset-inline-end: 0;
  cursor: pointer;
  height: 48px;
  width: 48px;
  align-items: center;
  justify-content: center;
  z-index: 1;

  svg {
    transform: rotate(-90deg);
  }

  ${({ $flip }) =>
    $flip &&
    css`
      inset-inline-start: 0;

      svg {
        transform: rotate(90deg);
      }
    `};
  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    display: flex;
  }
`;
export const ZoomContainer = styled.div`
  display: none;
  justify-content: flex-end;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    display: flex;
  }
`;
