import styled, { css } from 'styled-components';

import { spacing } from '@hultafors/hellberg/helpers';

export const DeskTopMenuWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
`;

export const DeskTopMenuContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0;
`;

interface DeskTopMenuContentWrapper {
  $show?: boolean;
  $type?: string;
}

export const DeskTopMenuContentWrapper = styled.div<DeskTopMenuContentWrapper>`
  position: absolute;
  box-sizing: border-box;
  display: none;
  height: 100%;
  width: 100%;
  margin-block: 0;
  margin-inline: auto;
  padding-block: 0;
  padding-inline: ${spacing.regular};
  opacity: 0;
  transition: opacity 0.2s linear;
  z-index: -1;

  ${({ $show }) =>
    $show &&
    css`
      display: block;
      opacity: 1;
      z-index: 1;
      transition: opacity 0.2s 0.3s linear;
    `}

  ${({ $type }) =>
    $type === 'ContentPlug' &&
    css`
      max-width: 337px;
      max-height: 337px;
    `}

  ${({ $type }) =>
    $type === 'ContentPlugGridChild' &&
    css`
      display: flex;
      justify-content: center;
      padding-block-start: ${spacing.large};
    `}
`;

interface IsSearchProps {
  $isSearch?: boolean;
}
