import Markdown from 'markdown-to-jsx';
import Image from 'next/image';
import Link from 'next/link';

import { useGlobal } from '@hultafors/hellberg/hooks';

import Logo from '../../svg/logo.svg';
import PartnerLogin from '../../svg/partner_login.svg';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { Section } from '../Section/Section';
import { SubscribeArea } from '../SubscribeArea/SubscribeArea';

import {
  BottomSection,
  BottomSectionLinks,
  Content,
  CookieSettings,
  FooterHead,
  FooterStyledOld,
  LogoLink,
  MiddleSection,
  PartnerLoginLink,
  SubscribeAreaWrapper,
} from './FooterOld.styled';

export const FooterOld = () => {
  const { shared, footer } = useGlobal();

  const handleOnPressSettings = () => {
    // TODO fix types for window
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.CookieScript.instance.show();
  };

  if (!footer) return null;

  const {
    partnerLogin,
    hultaforsBrands,
    introText,
    moreInfoText,
    subscribeInfoText,
    subscribeButtonText,
    userEmailFormUrl,
    userEmailFormImage,
    menu,
    socialMediaMenu,
    cookieSettings,
  } = footer;

  const onClickCookieSettings: React.MouseEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    event.preventDefault();
    handleOnPressSettings();
  };

  return (
    <FooterStyledOld>
      <Section noMarginTop>
        <Grid className="Grid">
          <GridChild>
            <FooterHead>
              <LogoLink href="/" aria-label="Hellberg Safety">
                <Logo
                  aria-hidden={true}
                  focusable={false}
                  width={34}
                  height={21}
                />
              </LogoLink>
              {shared?.urlToPartnerPortal && (
                <PartnerLoginLink
                  href={shared.urlToPartnerPortal}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {partnerLogin || shared.urlToPartnerPortal}
                  <PartnerLogin
                    aria-hidden={true}
                    focusable={false}
                    width={16}
                    height={16}
                  />
                </PartnerLoginLink>
              )}
            </FooterHead>
          </GridChild>
          <GridChild>
            <MiddleSection id="footer-middle-section">
              <Content>
                <div className="IntroText">
                  {hultaforsBrands.length > 0 && (
                    <Markdown
                      options={{ forceBlock: true }}
                      className="markdown-wrapper"
                    >
                      {introText ?? ''}
                    </Markdown>
                  )}
                  <BottomSectionLinks>
                    {hultaforsBrands.map((item, index) => {
                      return (
                        <Link
                          href={item.url ?? ''}
                          key={`Company-${index}`}
                          target="_blank"
                        >
                          {item.title}
                        </Link>
                      );
                    })}
                  </BottomSectionLinks>
                </div>
                <Markdown
                  options={{ forceBlock: true }}
                  className="markdown-wrapper"
                >
                  {moreInfoText ?? ''}
                </Markdown>
              </Content>

              {userEmailFormUrl && (
                <SubscribeAreaWrapper>
                  <SubscribeArea
                    subscribeInfoText={subscribeInfoText}
                    subscribeButtonText={subscribeButtonText}
                    userEmailFormUrl={userEmailFormUrl}
                    userEmailFormImage={userEmailFormImage}
                  />
                </SubscribeAreaWrapper>
              )}
            </MiddleSection>
            {menu && (
              <BottomSection>
                <BottomSectionLinks>
                  {menu.map((item, index) => {
                    return (
                      <Link href={item.url ?? ''} key={`Company-${index}`}>
                        {item.title}
                      </Link>
                    );
                  })}
                  <CookieSettings onClick={onClickCookieSettings}>
                    {cookieSettings}
                  </CookieSettings>
                </BottomSectionLinks>
                <div className="SocialMedia">
                  {socialMediaMenu.map((item, index) => {
                    if (item.url) {
                      return (
                        <Link
                          key={`SocialMedia-${index}`}
                          href={item.url ?? ''}
                          aria-label={item.icon?.title ?? ''}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Image
                            src={item.icon?.url ?? ''}
                            alt={item.icon?.alt ?? ''}
                            width={32}
                            height={32}
                          />
                        </Link>
                      );
                    }
                  })}
                </div>
              </BottomSection>
            )}
          </GridChild>
        </Grid>
      </Section>
    </FooterStyledOld>
  );
};
