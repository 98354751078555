import { CSSProperties } from 'styled-components';

import { DialogContent, DialogHeader, DialogWrapper } from './Dialog.styled';

interface DialogProps {
  closeButtonLabel?: React.ReactNode | string;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  isFullscreen?: boolean;
  children?: React.ReactNode;
  top?: CSSProperties['top'];
  right?: CSSProperties['right'];
  bottom?: CSSProperties['bottom'];
  left?: CSSProperties['left'];
  height?: CSSProperties['height'];
  width?: CSSProperties['width'];
}

export const Dialog: React.FC<DialogProps> = ({
  children,
  closeButtonLabel = 'Close',
  onClose,
  isFullscreen = true,
  height = '100%',
  width = '100%',
  top = 0,
  right = 0,
  bottom = 0,
  left = 0,
}) => (
  <DialogWrapper
    $width={width}
    $height={height}
    $top={top}
    $right={right}
    $bottom={bottom}
    $left={left}
  >
    <DialogHeader $isFullscreen={isFullscreen}>{closeButtonLabel}</DialogHeader>
    <DialogContent>{children}</DialogContent>
  </DialogWrapper>
);
