import { TextButtonStyled } from './TextButton.styled';
interface TextButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  center?: boolean;
  iconUrl?: string;
  iconLeft?: boolean;
  className?: string;
  children?: React.ReactNode;
  name?: string;
}
export const TextButton: React.FC<TextButtonProps> = ({
  onClick,
  children,
  className,
  name,
}) => {
  return (
    <TextButtonStyled className={className} onClick={onClick} aria-label={name}>
      {children}
    </TextButtonStyled>
  );
};
