import styled from 'styled-components';

import { colors } from '@hultafors/hellberg/helpers';

interface StyledRouterLinkProps {
  $linkType?: string;
}

export const StyledRouterLink = styled.span<StyledRouterLinkProps>`
  a {
    block-size: 100%;
    color: ${colors.black};
    border-block-end: ${({ $linkType }) =>
      $linkType === 'special' ? 'solid 1px #aaa' : 'none'};
    margin-block-start: ${({ $linkType }) =>
      $linkType === 'special' ? '30px' : 'unset'};
    margin-inline-end: ${({ $linkType }) =>
      $linkType === 'special' ? '20px' : 'unset'};
    text-decoration: none;
    transition: all 0.2s;

    &:hover {
      border-color: #555;
      color: ${colors.blackHover};
      transition: all 0.2s;
    }

    &:focus {
      color: inherit;
      box-shadow: none !important;
      outline: 0;
      border: 3px solid -webkit-focus-ring-color !important;
    }

    &:active {
      color: inherit;
    }
  }
`;
