import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hellberg/helpers';

export const StyledDynamicContent = styled.div`
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  margin-block: ${spacing.medium} ${spacing.large};
  margin-inline: auto;
  max-width: calc(100vw - 40px);
  width: 1000px;
  display: flex;
  flex-direction: column;

  > div:not(.markdown-wrapper) {
    margin-block: ${spacing.regular};
  }

  div[data-type='download'] {
    margin-block: 0;
    margin-inline: auto;
    inline-size: 100%;
  }

  > * > div:last-of-type {
    padding-block-end: 0;
  }

  /* @media screen and (min-width: ${breakpoints.tablet}) {
    > div:not(.markdown-wrapper) {
      margin-block: ${spacing.medium};
    }
  } */
`;
