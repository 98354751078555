import { HTag } from '../HTag/HTag';
import { Markdown } from '../Markdown/Markdown';
import { Paragraph } from '../Paragraph/Paragraph';

import { StyledGreyInfoBox } from './GreyInfoBox.styled';
interface GreyInfoBoxProps {
  title: string;
  body: string;
  scrollId?: string | null | undefined;
}
export const GreyInfoBox: React.FC<GreyInfoBoxProps> = ({
  title,
  body,
  scrollId,
}) => {
  if (!body) {
    return null;
  }

  const sanitizedScrollId =
    scrollId?.replace(/[^a-zA-Z0-9-_:.]/g, '') || undefined;

  return (
    <StyledGreyInfoBox id={sanitizedScrollId}>
      <div className="content">
        <HTag type="h2" styleType="header4">
          {title}
        </HTag>
        <Markdown
          options={{
            overrides: {
              p: {
                component: Paragraph,
                props: {
                  styleType: 'body16',
                },
              },
            },
          }}
        >
          {body}
        </Markdown>
      </div>
    </StyledGreyInfoBox>
  );
};
